import {CenterRequest} from '../../networking/requests/Center';
import {check, input, useForm, V} from 'buro-lib-ts';
import Center from '../../networking/models/Center';
import User from '../../networking/models/User';

export interface CenterForm extends Omit<CenterRequest, 'contact_id' | 'audit_term'> {
    audit_term: string;
}

// TODO: center as param or object for default values
export const useCenterForm = (center?: Center, users?: User[]) => { // TODO: Use function or attribute map to convert to form values?
    const form = useForm<CenterForm>({
        name: input('', [V.required]),
        city: input('', [V.required]),
        street: input('', [V.required]),
        housenumber: input('', [V.required]),
        postal_code: input('', [V.required]),
        phonenumber: input('', [V.required]),
        email: input('', [V.required]),
        anonymous_auditors: check(false),
        sso: check(false),
        nts_form: check(false),
        audit_term: input('6')
    });

    if(center)
        form[0].set({
            ...center,
            audit_term: center.audit_term.toString()
        });

    return form;
};
