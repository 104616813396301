import React, { FunctionComponent } from 'react';

import AuditCall from '../../../../networking/models/AuditCall';

import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { getFormattedTime } from '../../../../helpers/Time';
import {useTheme} from '../../../contexts/ThemeContext';

interface Props {
    serialNumber: number;
    auditCall: AuditCall;
    onDeleteClick: (auditCall: AuditCall) => void;
}

const UploadedAuditCall: FunctionComponent<Props> = ({ auditCall, serialNumber, onDeleteClick }) => {
    const { theme } = useTheme();

    const onDelete = () => {
        onDeleteClick(auditCall);
    };

    return (
        <div className={`item--uploaded conversation--${theme.modifier}`}>
            <div className={'item-list__grid item-list__grid--small'}>
                <span>{ serialNumber }</span>
                <span>{ auditCall.created_at }</span>
                <span>{ getFormattedTime(auditCall.duration) }</span>
                <span><DeleteIcon className={'icon-grey icon-grey--filled icon-pointer'} onClick={onDelete} /></span>
            </div>
        </div>
    );
};

export default UploadedAuditCall;
