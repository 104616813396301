import React, { FunctionComponent } from 'react';

interface Props {
    height: number;
}

const AudioBar: FunctionComponent<Props> = ({ height }) => {
    return (
        <div className={'audio-player__audio-bar'} style={{ height: `${height}px` }} />
    );
};

export default AudioBar;

