import React, { FunctionComponent } from 'react';
import FormPart from '../../../../../networking/models/FormPart';
import PartShortcut from '../../../../client/partials/conversations/form/PartShortcut';
import {Draggable} from 'react-beautiful-dnd';

interface Props {
    part: FormPart;
    index: number;
}

const DraggableShortcut: FunctionComponent<Props> = ({ part, index }) => {

    return (
        <Draggable draggableId={part.id!.toString()} index={index}>
            {(provided, snapshot) => (
                <div { ...provided.draggableProps }
                    { ...provided.dragHandleProps }
                    ref={provided.innerRef}
                    className={`admin-form-detail__drag-container ${snapshot.isDragging ? 'admin-form-detail__drag-container--dragging' : ''}`}>
                    <PartShortcut part={part} index={index} />
                </div>
            )}
        </Draggable>
    );
};

export default DraggableShortcut;
