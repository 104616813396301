import React, {FunctionComponent, useEffect } from 'react';
import {input, useForm, V} from 'buro-lib-ts';

import FormPart from '../../../../networking/models/FormPart';
import CancelButton from '../../../layout/buttons/CancelButton';
import Button from '../../../layout/buttons/Button';
import Input from '../../../client/partials/input/Input';

interface Props {
    part: FormPart;
    onSave: (part: FormPart) => void;
    onCancel: () => void;
}

// TODO: Refactor
const PartModal: FunctionComponent<Props> = ({ part, onSave, onCancel }) => {
    const [form, onFormChange] = useForm<{ title: string }>({
        title: input('', [V.required])
    });

    useEffect(() => {
        form.set(part);
    }, [part]);

    const save = () => onSave({ ...part, ...form.values });

    return (
        <div className={'part-modal'}>
            <p className={'part-modal__title'}>{ part.id ? 'Fase aanpassen' : 'Nieuwe fase' }</p>

            <Input
                id={'title'}
                text={'Titel'}
                form={form}
                onChange={onFormChange} />

            <div className={'part-modal__actions'}>
                <CancelButton onClick={onCancel}>Annuleren</CancelButton>
                <Button onClick={save} disabled={!form.isValid}>Opslaan</Button>
            </div>
        </div>
    );
};

export default PartModal;
