import { sessionStore } from 'buro-lib-ts';

class TokenService {

    private static STORAGE_IDENTIFIER: string = 'auth_token';

    static setToken(token: string) {
        sessionStore.setItem(TokenService.STORAGE_IDENTIFIER, token);
    }

    static getToken() {
        if(!this.isAuthenticated()) {
            throw new Error('Token is not set!');
        }

        return sessionStore.getItem(TokenService.STORAGE_IDENTIFIER);
    }

    static isAuthenticated() {
        const token = sessionStore.getItem(TokenService.STORAGE_IDENTIFIER);

        return token !== null;
    }

    static removeToken() {
        sessionStore.removeItem(TokenService.STORAGE_IDENTIFIER);
    }
}

export default TokenService;
