import React, { FunctionComponent } from 'react';

import AuditCall from '../../../../networking/models/AuditCall';

import UploadedAuditCall from './UploadedAuditCall';
import {useTheme} from '../../../contexts/ThemeContext';

interface Props {
    auditCalls: AuditCall[];
    onDeleteClick: (auditCall: AuditCall) => void;
}

const SmallConversationList: FunctionComponent<Props> = ({ auditCalls, onDeleteClick }) => {
    const { theme } = useTheme();

    return (
        <div className={`item-list--uploaded item-list--${theme.modifier}`}>
            <div className={'item-list__grid item-list__grid--small'}>
                <span className={'item-list__title'}>Nr.</span>
                <span className={'item-list__title'}>Datum</span>
                <span className={'item-list__title'}>Tijd</span>
                <span className={'item-list__title'} />
            </div>

            { auditCalls.map((auditCall, index) => (
                <UploadedAuditCall
                    auditCall={auditCall}
                    serialNumber={index + 1}
                    onDeleteClick={onDeleteClick}
                    key={auditCall.id} />
            )) }
        </div>
    );
};

export default SmallConversationList;
