import React, { FunctionComponent } from 'react';

import PartShortcut, { Props as PartShortcutProps } from '../../../partials/conversations/form/PartShortcut';
import ProgressCircle from '../../../../utils/ProgressCircle';
import {ReactComponent as ArrowIcon} from '../../../../../assets/icons/arrow.svg';

interface Props extends PartShortcutProps {
    total: number;
    answered: number;
    index: number;
}

const FormPartShortcut: FunctionComponent<Props> = ({ part, active, total, answered, index }) => {

    return (
        <PartShortcut part={part} active={active} index={index}>
            <div className={'box-shortcut__content box-shortcut__content--right'}>
                {
                    total ?
                        <ProgressCircle total={total} finished={answered} />
                        :
                        <ArrowIcon className={'icon-grey icon-grey--filled box-shortcut__arrow'} />
                }
            </div>
        </PartShortcut>
    );
};

export default FormPartShortcut;
