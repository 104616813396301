import React, { FunctionComponent } from 'react';
import FormPart from '../../../../../networking/models/FormPart';
import BoxShortcut from '../../box/BoxShortcut';

export interface Props {
    part: FormPart;
    active?: boolean;
    children?: any;
    index: number;
}

const PartShortcut: FunctionComponent<Props> = ({ part, active, children, index }) => {
    const dividerPos = part.title.search(':');
    const title = part.title.substr(0, dividerPos);
    const desc = part.title.substr(dividerPos + 1);

    return (
        <BoxShortcut title={title ? title : part.title} desc={desc} active={active} index={index}>
            { children }
        </BoxShortcut>
    );
};

export default PartShortcut;
