import {ModelRepository, RequestMethod} from 'buro-lib-ts';

import Center from '../models/Center';
import ScoresPerQuestion from 'networking/models/ScoresPerQuestion';
import {CenterRequest} from '../requests/Center';
import Form from '../models/Form';

interface CenterSso {
    id: number;
    name: string;
    image_url: string;
}

type GetCentersResponse = CenterSso[];

class CenterRepository extends ModelRepository<Center> {

    public constructor() {
        super(`/centers`);
    }

    public create(request: CenterRequest) {
        return this.request(this.url(), RequestMethod.POST, request).send();
    }

    public update(id: number, request: CenterRequest) {
        return this.request<Center>(this.eUrl(id), RequestMethod.PUT, request).send();
    }

    public delete(id: number) {
        return this.request<Center>(this.eUrl(id), RequestMethod.DELETE).send();
    }

    public attachCenterToForms(centerId: number, forms: Form[]) {
        return this.request(this.url(`/${centerId}/attach`), RequestMethod.POST, {
            forms
        }).send();
    }

    public getCentersSso() {
        return this.request<GetCentersResponse>(this.url(`/sso`), RequestMethod.GET).send();
    }

    public setAnonymousAuditors(centerId: number) {
        return this.request<Center>(this.url(`/${centerId}/anonymousAuditors`), RequestMethod.PUT).send();
    }

    public getCenterScoresPerForm(centerId: number, formId: number) {
        return this.request<ScoresPerQuestion[]>(this.url(`/${centerId}/form-scores?form=${formId}`), RequestMethod.GET).send();
    }

    public getScoresPerFormPerOperator(centerId: number, formId: number, operatorId: number) {
        return this.requestWithOptions<ScoresPerQuestion[]>(this.url(`/${centerId}/operator-criteria-scores`), RequestMethod.GET, {
            params: { form: formId, operator: operatorId }
        }).send();
    }
}

export default CenterRepository;
