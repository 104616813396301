import {useMutation} from '@tanstack/react-query';

import {useQueryDataSetterActions} from '../Utils';
import AuditRepository from '../../../networking/repos/AuditRepository';

export const useAuditMutations = (id: number) => {
    const { setData } = useQueryDataSetterActions(['audit-detail', id]);
    const repo = new AuditRepository();

    return {
        finishAudit: useMutation({
            mutationFn: (sendMail: boolean) => repo.finishAudit(id, sendMail),
            onSuccess: setData
        })
    };
};
