import React, { FunctionComponent, MouseEvent } from 'react';

interface Props {
    children: any;
    selected: boolean;
    onSelect: () => void;
}

const Option: FunctionComponent<Props> = ({ children, selected, onSelect }) => {

    const onOptionClick = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        onSelect();
    };

    return (
        <div
            className={`dropdown__option ${selected ? 'dropdown__option--selected' : ''}`}
            onClick={onOptionClick}>

            <p>{ children }</p>
        </div>
    );
};

export default Option;