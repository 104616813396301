import Transformer from '../base/Transformer';
import User from '../../models/User';

class UserTransformer implements Transformer {

    public transform(user: User): any {
        return { ...user, roles: user.roles.map(r => r.role) };
    }
}

export default UserTransformer;