import React, {FunctionComponent, useEffect, useRef} from 'react';

import {useBoxList} from '../../../contexts/BoxListContext';
import {useTheme} from '../../../contexts/ThemeContext';

interface Props {
    children: any;
    onLoad?: (boxData: BoxData) => void;
}

export interface BoxData {
    position: number;
    height: number;
}

const Box: FunctionComponent<Props> = ({ children }) => {
    const { theme } = useTheme();
    const { onBoxLoad, renderId } = useBoxList();

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setTimeout(() => {
            if(!ref.current || !onBoxLoad) {
                return;
            }

            onBoxLoad({
                position: ref.current.offsetTop,
                height: ref.current.clientHeight
            });
        }, 200);
    }, [renderId]);

    return (
        <div className={`box box--${theme.modifier}`} ref={ref}>
            { children }
        </div>
    );
};

export default Box;
