import {ModelRepository, RequestMethod, ResponseType} from 'buro-lib-ts';

import AuditCriteria from 'networking/models/AuditCriteria';
import Audit from '../models/Audit';
import Score from '../models/Score';
import Form from '../models/Form';
import {AuditIndexRequest} from '../requests/Audit';

class AuditRepository extends ModelRepository<Audit> {

    public constructor() {
        super('/audits');
    }

    public getAuditorAudits(filters: AuditIndexRequest = {}, page?: number) {
        return this.getAuditsForType('auditor', filters, page);
    }

    public getOperatorAudits(filters: AuditIndexRequest = {},  page?: number) {
        return this.getAuditsForType('operator', filters, page);
    }

    public getOpenAudits(filters: AuditIndexRequest = {}, page?: number) {
        return this.getAuditsForType('open', filters, page);
    }

    public getPassedAudits(filters: AuditIndexRequest = {}, page?: number) {
        return this.getAuditsForType('passed', filters, page);
    }

    public getFailedAudits(filters: AuditIndexRequest = {}, page?: number) {
        return this.getAuditsForType('failed', filters, page);
    }

    public getAuditsForType(type: string, filters: AuditIndexRequest = {}, page?: number) {
        const pageParam = (page && page > 1) ? `?page=${page}` : '';

        return this.requestWithOptions<Audit[]>(this.url(pageParam), RequestMethod.GET, {
            params: {type, ...filters}
        }).sendPagination();
    }

    public finishAuditCreate(audit: Audit) {
        return this.request(this.eUrl(audit, '/finishCreate'), RequestMethod.POST, {}).send();
    }

    public finishAudit(audit: number, mail: boolean) {
        return this.requestWithOptions<Audit>(this.eUrl(audit, '/finish'), RequestMethod.POST, {
            params: {mail},
        }).send();
    }

    public getFile(id: number, type: string) {
        return this.requestWithOptions<ArrayBuffer>(this.url(`/${id}/file?type=${type}`), RequestMethod.GET, {
            responseType: ResponseType.ARRAYBUFFER
        }).sendRaw();
    };

    public getAuditScore(id: number) {
        return this.request<Score[]>(this.url(`/${id}/scores`), RequestMethod.GET).send();
    }

    public getFailResults(auditId: number) {
        return this.request<AuditCriteria[]>(this.url(`/${auditId}/fail-results`), RequestMethod.GET).send();
    }

    public getForm(audit: Audit) {
        return this.request<Form>(this.eUrl(audit, '/form'), RequestMethod.GET).send();
    }

    public updateComment(audit: Audit) {
        return this.request(this.eUrl(audit, '/comment'), RequestMethod.PUT, audit).send();
    }

    public updateReflection(audit: Audit) {
        return this.request(this.eUrl(audit, '/reflection'), RequestMethod.PUT, audit).send();
    }

    public onDownload(audit: Audit) {
        return this.request(this.eUrl(audit, '/download-trigger'), RequestMethod.POST, {}).send();
    }

    public softDelete(audit: Audit) {
        return this.request(this.eUrl(audit, `/softDelete`), RequestMethod.POST).send();
    }

    public restore(audit: Audit) {
        return this.request(this.eUrl(audit, `/restore`), RequestMethod.POST).send();
    }
}

export default AuditRepository;
