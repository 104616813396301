import React, {FunctionComponent, useState} from 'react';

import WayPoint from 'networking/models/WayPoint';

import { getFormattedTime } from 'helpers/Time';
import Button from '../../../../layout/buttons/Button';
import IconButton from '../../../../layout/buttons/IconButton';
import WavePointInput from './WavePointInput';

import {ReactComponent as EditIcon} from '../../../../../assets/icons/bewerken.svg';
import {useTheme} from '../../../../contexts/ThemeContext';

interface Props {
    wayPoint: WayPoint;
    onEdit: (wayPoint: WayPoint) => void;
    onDeleteClick: (wayPoint: WayPoint) => void;
    index: number;
    editable: boolean;
}

const WavePointComment: FunctionComponent<Props> = ({ wayPoint, onEdit, index, onDeleteClick, editable }) => {
    const { theme } = useTheme();

    const [editing, setEditing] = useState<boolean>(false);
    const [wayPointComment, setWayPointComment] = useState<string>(wayPoint.comment ?? '');

    const toggleEditing = () => {
        setEditing(!editing);
    };

    const renderDeleteButton = (onClick: () => void, className: string) => {
        return (
            <Button onClick={onClick} del className={className}>Verwijderen</Button>
        );
    };

    const onDeleteButtonClick = () => {
        onDeleteClick(wayPoint);
    };

    const onWayPointInputChange = (index: number, value: string) => {
        setWayPointComment(value);
    };

    const onSubmit = () => {
        const newWayPoint = { ...wayPoint, comment: wayPointComment };

        toggleEditing();

        onEdit(newWayPoint);
    };

    return (
        <React.Fragment>
            { editing &&
                <WavePointInput
                    onCancel={onDeleteButtonClick}
                    index={index}
                    value={wayPointComment}
                    wavePoint={wayPoint}
                    onInputChange={onWayPointInputChange}
                    onSubmit={onSubmit}
                    renderCancelButton={renderDeleteButton} />
            }

            { (!editing) &&
                <div className={`wave-point-comment wave-point-comment--${theme.modifier} wave-point-comment-list__grid`}>
                    <span className={'wave-point-comment__text wave-point-comment__text--bold'}>{ wayPoint.index }.</span>
                    <span className={'wave-point-comment__text wave-point-comment__text--bold'}>{ getFormattedTime(wayPoint.seconds) }</span>
                    <span className={'wave-point-comment__text'}>{ wayPoint.comment }</span>

                    {editable && 
                        <IconButton icon={EditIcon} onClick={toggleEditing} />
                    }
               
                </div>
            }
        </React.Fragment>
    );
};

export default WavePointComment;
