import {Model} from 'buro-lib-ts';
import {useQueryClient} from '@tanstack/react-query';

import {PaginationQueryConfig, QueryConfig} from './Types';
import {usePaginationState} from '../../helpers/Hooks';

export const useIndexQueryConfig = <T>(key: any[], queryFn: (page: number) => Promise<T>): PaginationQueryConfig<T> => {
    const pagination = usePaginationState();

    return [{
        queryKey: [...key, pagination[0]],
        queryFn: () => queryFn(pagination[0]),
        keepPreviousData: true,
    }, ...pagination];
};

export const useQueryConfig = <T>(queryKey: any[], queryFn: () => Promise<T>): [QueryConfig<T>] => {
    return [{ queryKey, queryFn }]
};

// TODO: test if works
export const useQueryDataSetterActions = <T extends Partial<Model>>(key: any[]) => {
    const queryClient = useQueryClient();

    return {
        setData: (data: T) => queryClient.setQueryData(key, () => {
            return data;
        }),
        addData: (data: T) => queryClient.invalidateQueries(key),
        updateData: (data: T) => queryClient.invalidateQueries(key),
        deleteData: (data: T) => queryClient.invalidateQueries(key),
    };
};
