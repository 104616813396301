import React, { FunctionComponent } from 'react';
import {useTheme} from '../contexts/ThemeContext';


interface Props {
    className?: string;
}

const CheckIcon: FunctionComponent<Props> = ({ className }) => {
    const { theme } = useTheme();

    return (
        <svg className={`progress-icon progress-icon--tick progress-icon--${theme.modifier} ${className ? className : ''}`} viewBox="0 0 24 24">
            <path d="M4.1 12.7L9 17.6 20.3 6.3" />
        </svg>
    );
};

export default CheckIcon;
