import {ModelRepository, RequestMethod} from 'buro-lib-ts';

import User from '../models/User';

import {CenterUserRequest} from '../requests/CenterUser';

class SuperAdminRepository extends ModelRepository<User> {

    public constructor() {
        super(`/users`);
    }

    create(request: CenterUserRequest) {
        return this.request(this.url(), RequestMethod.POST, request).send();
    }

    update(id: number, request: CenterUserRequest) {
        return this.request(this.eUrl(id), RequestMethod.PUT, request).send();
    }

    delete(id: number) { // TODO: Add in base repo
        return this.request<User>(this.eUrl(id), RequestMethod.DELETE).send();
    }

    softDelete(id: number) {
        return this.request(this.eUrl(id, `/softDelete`), RequestMethod.POST).send();
    }

    restore(id: number) {
        return this.request(this.eUrl(id, `/restore`), RequestMethod.POST).send();
    }
}

export default SuperAdminRepository;
