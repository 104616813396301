import React, { FunctionComponent, useRef } from 'react';

interface Props {
    checked: boolean;
    value: number;
    onChange: (value: number) => void;
    onClick: (value: number) => void;
    color: 'green' | 'red' | 'orange';
}

const FormQuestionRadioInput: FunctionComponent<Props> = ({ onClick, checked, value, color }) => {

    const ref = useRef<HTMLInputElement>(null);

    const onRadioClick = () => {
        onClick(value);

        ref.current?.blur();
    };

    return (
        <input
            type={'radio'}
            name={'answer'}
            value={value}
            className={`form-question__radio form-question__radio--${color}`}
            checked={checked}
            onClick={onRadioClick}
            onChange={() => null}
            ref={ref} />
    );
};

export default FormQuestionRadioInput;
