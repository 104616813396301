import React, {FunctionComponent, createContext, useContext, useState} from 'react';

interface SideBarMenuContext {
    menuOpen: boolean;
    toggleMenu: () => void;
}

const SideBarContext = createContext({} as SideBarMenuContext);

export const useSideBar = () => useContext(SideBarContext);

interface Props {
    children: any;
}

const SideBarContextProvider: FunctionComponent<Props> = ({ children }) => {
    const [open, setOpen] = useState<boolean>(true);

    const toggleMenu = () => {
        setOpen(!open);
    };

    return (
        <SideBarContext.Provider value={{ menuOpen: open, toggleMenu }}>
            { children }
        </SideBarContext.Provider>
    );
};

export default SideBarContextProvider;