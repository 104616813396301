import {Errors, V, Validation, ValidationMessage} from 'buro-lib-ts';

export const notEmpty = (object: any, fields: string[]) => {
    for (const field of fields) {
        if (!object.hasOwnProperty(field)) {
            continue;
        }

        if (!object[field] || object[field] === '') {
            return false;
        }
    }

    return true;
};

export const MV = {
    ...V,
    numbers: (amount: number) => (value: string) => {
        const matches: any = value.matchAll(/[0-9]/g);

        if ([...matches].length > 4)
            return Validation.invalid(Validation.message('validation.numbers', amount));

        return Validation.valid();
    },
    validDutchPostalCode: () => (value: string) => {
        const matches = value.match(/^[1-9][0-9]{3} ?(?!sa|sd|ss|SA|SD|SS)[A-Za-z]{2}$/);

        if (!matches)
            return Validation.invalid(Validation.message('validation.validDutchPostalCode'));

        return Validation.valid();
    },
    dateBetween: (start: Date, end: Date) => (value: string) => {
        const date = new Date(value);

        if (date < start || date > end)
            return Validation.invalid(Validation.message('validation.dateBetween', start.toLocaleDateString(), end.toLocaleDateString()));

        return Validation.valid();
    },
    validTime: () => (value: string) => {
        const matches = value.match(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/);

        if (!matches)
            return Validation.invalid(Validation.message('validation.validTime'));

        return Validation.valid();
    },
    phoneNumber: () => (value: string) => {
        if (value === '') return Validation.valid();
        const matches = value.match(/^(?:0|(?:\+|00) ?31 ?)(?:(?:[1-9] ?(?:[0-9] ?){8})|(?:6 ?-? ?[1-9] ?(?:[0-9] ?){7})|(?:[1,2,3,4,5,7,8,9]\d ?-? ?[1-9] ?(?:[0-9] ?){6})|(?:[1,2,3,4,5,7,8,9]\d{2} ?-? ?[1-9] ?(?:[0-9] ?){5}))$/);

        if (!matches)
            return Validation.invalid(Validation.message('validation.phoneNumber'));

        return Validation.valid();
    },
    houseNumber: () => (value: string) => {

        const matches = value.match(/^[1-9][0-9]{0,3}[a-zA-Z]{0,3}$/);

        if (!matches)
            return Validation.invalid(Validation.message('validation.houseNumber'));

        return Validation.valid();
    }
};


export const messageParser = (fieldName: string, key: string, ...args: any[]) : string => {
    const messages: any = {
        'validation.empty': 'is verplicht',
        'validation.size': 'moet tussen {0} en {1} karakters bevatten',
        'validation.email': 'is geen geldig e-mailadres',
        'validation.numbers': 'veld mag maximaal {0} cijfers bevatten',
        'validation.validDutchPostalCode': 'is geen geldige postcode',
        'validation.dateBetween': 'moet tussen {0} en {1} liggen',
        'validation.validTime': 'is geen geldige tijd',
        'validation.phoneNumber': 'is geen geldig telefoonnummer',
        'validation.houseNumber': 'is geen geldig huisnummer',
    };

    if (messages[key]) {
        return `Het ${fieldName} veld ` + messages[key].replace('{0}', args[0]).replace('{1}', args[1]);
    }

    return key;
}

export const parseError = (field: string, errors: ValidationMessage[] | undefined) : null | String[] => {
    if (!errors) {
        return null;
    }
    const parsedErrors: any = [];

    for (const error of errors) {
        parsedErrors.push(messageParser(field, error.key, ...error.values));
    }

    return parsedErrors;
}

export const parseErrors = (errors: Errors<any>, fieldNames : any ={}) : null | String[] => {
    const parsedErrors: any = [];

    for (const field in errors) {
        let fieldName = fieldNames[field] ? fieldNames[field] : field;
        let parsedFieldErrors = parseError(fieldName, errors[field]);
        if (parsedFieldErrors) {
            parsedErrors.push(...parsedFieldErrors);
        }
    }

    return parsedErrors;
}

export const validateNumber = (value: string, min: number, max: number) => {
    if(isNaN(Number(value))) {
        return Validation.invalid(Validation.message('Waarde moet een getal zijn!'));
    }

    const number = parseInt(value);

    if(number < min || number > max) {
        return Validation.invalid(Validation.message(`Waarde moet tussen de ${min} en ${max} zijn!`));
    }

    return Validation.valid();
};
