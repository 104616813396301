import React, { FunctionComponent, Children } from 'react';

import { TabControl, TabContent, TabControlProps } from 'buro-lib-ts';

import TabBar from './TabBar';
import Tab from './Tab';

import TabNavigationContent from './TabNavigationContent';
import {useTheme} from '../../contexts/ThemeContext';

interface Props extends TabControlProps {
    scrollable?: any;
    className?: string;
    noErrors?: boolean;
}

const Tabs: FunctionComponent<Props> = (props) => {
    const { noErrors, deps, children, scrollable, className } = props;
    const { theme } = useTheme();

    // TODO: Fix any
    const TabBarElement = Children.toArray(children).filter((child: any) => child.type === TabBar);

    const tabContents = Children.toArray(children).filter((child: any) => child.type === TabContent);

    const tabType = props.tabType ?? Tab;

    return (
        <div className={`tab-navigation tab-navigation--${theme.modifier} ${className ? className : ''}`}>
            <TabControl {...props} tabType={tabType} deps={deps} noErrors={noErrors}>
                { TabBarElement }

                <TabNavigationContent scrollable={scrollable}>
                    { tabContents }
                </TabNavigationContent>
            </TabControl>
        </div>
    );
};

export default Tabs;
