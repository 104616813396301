import {CenterUserRequest} from '../../networking/requests/CenterUser';
import {input, select, useForm, V} from 'buro-lib-ts';
import {MV} from "../../helpers/Validation";

export interface UserForm extends Omit<CenterUserRequest, 'roles'> {}

export const useUserForm = (center?: number) => {
    return useForm<UserForm>({
        first_name: input('', [V.required, V.size(1, 50)]),
        last_name: input('', [V.required, V.size(1, 50)]),
        phone: input('', [MV.phoneNumber()]),
        email: input('', [V.required, V.email, V.size(1, 50)]),
        center: select<UserForm, number>(center ?? -1, null, []),
        residence: input('', [V.size(1, 100)]),
    });
};
