import React, { FunctionComponent } from 'react';

interface Props {
    children: any;
    active?: boolean;
}

const BoxHeader: FunctionComponent<Props> = ({ children, active }) => {
    return (
        <div className={`box__header ${active && 'box__header--active'}`}>
            <span>{ children }</span>
        </div>
    );
};

export default BoxHeader;