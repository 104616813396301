import {useQueryClient} from '@tanstack/react-query';

import {useIndexQueryConfig} from '../Utils';
import AuditCallRepository from '../../../networking/repos/AuditCallRepository';


export const useAuditCallIndexQueryConfig = (audit: number) => {
    const repo = new AuditCallRepository(audit);

    return useIndexQueryConfig(['audit-calls'], () => repo.all());
};

export const useAuditCallDetailQueryConfig = (auditId: number, auditCallId: number) => {
    const repo = new AuditCallRepository(auditId);

    return [{
        queryKey: ['audit-calls', auditCallId],
        queryFn: () => repo.findWithParts(auditCallId)
    }];
};

export const useAuditCallScoresQueryConfig = (auditId: number, auditCallId: number) => {
    const repo = new AuditCallRepository(auditId);

    return [{
        queryKey: ['audit-calls', 'scores', auditCallId],
        queryFn: () => repo.getScores(auditCallId)
    }];
};

// TODO: Create revoke objectUrl hook
export const useAuditCallNtsUrlQueryConfig = (auditId: number, auditCallId: number) => {
    const repo = new AuditCallRepository(auditId);

    const client = useQueryClient();
    const olData: string | undefined = client.getQueryData(['audit-calls', 'nts', auditCallId]);

    return [{
        queryKey: ['audit-calls', 'nts-form', auditCallId],
        queryFn: async () => {
            if(olData)
                window.URL.revokeObjectURL(olData);

            const buffer = await repo.getFile(auditCallId, 'nts');

            return window.URL.createObjectURL(
                new Blob([buffer], {type: "application/pdf"})
            );
        }
    }];
};

export const useAuditCallAudioFileBufferQueryConfig = (auditId: number, auditCallId: number) => {
    const repo = new AuditCallRepository(auditId);

    return [{
        queryKey: ['audit-calls', 'audio', auditCallId],
        queryFn: () => repo.getFile(auditCallId, 'audio')
    }];
};


