import {ModelRepository, RequestMethod, ResponseType} from 'buro-lib-ts';

import AuditCall from '../models/AuditCall';
import Question from '../models/Question';
import Score from '../models/Score';

class AuditCallRepository extends ModelRepository<AuditCall> {

    public constructor(audit: number) {
        super(`/audits/${audit}/calls`);
    }

    public findWithParts(id: number): Promise<AuditCall> {
        return this.request<AuditCall>(this.url(`/${id}?withParts`), RequestMethod.GET).send();
    }

    public answerQuestion(auditCall: number, question: Question, answer: number) {
        return this.request(this.url(`/${auditCall}/answer-question`), RequestMethod.POST, {
            question_id: question.id,
            answer: answer
        }).send();
    }

    public getFile(id: number, type: string) {
        return this.requestWithOptions<ArrayBuffer>(this.url(`/${id}/file?type=${type}`), RequestMethod.GET, {
            responseType: ResponseType.ARRAYBUFFER
        }).sendRaw();
    };

    public uploadFile(auditCall: AuditCall, data: FormData, onUploadProgress: (event: ProgressEvent) => void) {
        return this.requestWithOptions(this.eUrl(auditCall, '/upload-file'), RequestMethod.POST, {
            data: data,
            onUploadProgress: onUploadProgress
        }).send();
    }

    public finishAuditCall(auditCallId: number) {
        return this.request(this.eUrl(auditCallId, '/finish'), RequestMethod.POST).send();
    }

    public updateEntranceData(auditCall: AuditCall) {
        return this.request(this.eUrl(auditCall, '/update-entrance'), RequestMethod.PUT, auditCall).send();
    }

    public updateComment(auditCall: AuditCall) {
        return this.request(this.eUrl(auditCall, '/comment'), RequestMethod.PUT, auditCall).send();
    }

    public unlockAuditCall(auditCallId: number) {
        return this.request(this.eUrl(auditCallId, '/unlock'), RequestMethod.POST).send();
    }

    public getScores(auditCallId: number) {
        return this.request<Score[]>(this.eUrl(auditCallId, '/scores'), RequestMethod.GET).send();
    }
}

export default AuditCallRepository;
