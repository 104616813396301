import React, { FunctionComponent } from 'react';
import { Droppable} from 'react-beautiful-dnd';

import FormPart from '../../../../networking/models/FormPart';
import Question from '../../../../networking/models/Question';
import AddButton from '../../layout/AddButton';
import DraggableQuestion from './DraggableQuestion';

interface Props {
    part: FormPart;
    onQuestionAddClick: (part: FormPart) => void;
    onQuestionEdit: (question: Question) => void;
    toggleDeleteQuestionModal: () => void;
    setSelectedQuestion: Function
}

const Part: FunctionComponent<Props> = ({ part, onQuestionAddClick, onQuestionEdit, toggleDeleteQuestionModal, setSelectedQuestion }) => {
    const questions: Question[] = part.questions!;

    return (
        <Droppable droppableId={part.id!.toString()}>
            {(provided) => (
                <div ref={provided.innerRef} { ...provided.droppableProps }>
                    { questions.map((question, index) => (
                        <DraggableQuestion
                            key={question.id}
                            index={index}
                            question={question}
                            onEdit={onQuestionEdit}
                            toggleDeleteQuestionModal={toggleDeleteQuestionModal}
                            setSelectedQuestion={setSelectedQuestion}
                            partId={part.id}/>
                    )) }

                    { provided.placeholder }

                    <div className={'add-button__container'}>
                        <AddButton onClick={() => onQuestionAddClick(part)}>Vraag toevoegen</AddButton>
                    </div>
                </div>
            )}
        </Droppable>
    );
};

export default Part;
