import React, {FunctionComponent, MouseEvent, useRef, useEffect, useState} from 'react';

import WayPoint from 'networking/models/WayPoint';
import { getFormattedTime } from 'helpers/Time';

interface Props {
    length: number;
    value: number;
    onClick: (seconds: number) => void;
    onBarDoubleClick: (seconds: number) => void;
    wavePoints: WayPoint[];
}

const ProgressBar: FunctionComponent<Props> = ({ length, value, onClick, onBarDoubleClick, wavePoints }) => {
    const [loaded, setLoaded] = useState<boolean>(false);
    const progressRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setLoaded(true);
    }, []);

    const getWidth = (): number => {
        return value / length * 100;
    };

    const getClickedSeconds = (mousePosition: number): number => {
        if(progressRef.current === null) {
            return 0;
        }

        const barPosition = progressRef.current.getClientRects()[0];
        const width = progressRef.current.offsetWidth;

        return length / width * (mousePosition - barPosition.left);
    };

    const onBarClick = (e: MouseEvent<HTMLDivElement>) => {
        const seconds = getClickedSeconds(e.clientX);

        onClick(seconds);
    };

    const onDoubleClick = (e: MouseEvent<HTMLDivElement>) => {
        const seconds = getClickedSeconds(e.clientX);

        onBarDoubleClick(seconds);
    };

    const getDuration = () => {
        return getFormattedTime(length);
    };

    const getTimeLeft = () => {
        return getFormattedTime(value);
    };

    const getWavePointPosition = (wavePointSeconds: number) => {
        if(progressRef.current === null ) return 0;

        return (progressRef.current.offsetWidth / length) * wavePointSeconds;
    };

    return (
        <div className={'audio-player__progress-container audio-player__container'}>
            <span className={'audio-player__duration-text audio-player__duration-text--blue'}>{ getTimeLeft() }</span>

            <div className={'audio-player__progress'} onClick={onBarClick} onDoubleClick={onDoubleClick} ref={progressRef}>
                <div className={'audio-player__progress-bar'} style={{ width: `${getWidth()}%` }} />

                { loaded && wavePoints.map((wavePoint, index) => (
                    <div
                        className={'audio-player__wave-point audio-player__wave-point--bar'}
                        style={{ left: `${getWavePointPosition(wavePoint.seconds)}px` }}
                        key={index} />
                )) }
            </div>

            <span className={'audio-player__duration-text'}>{ getDuration() }</span>
        </div>
    );
};

export default ProgressBar;
