import React, { FunctionComponent } from 'react';

interface Props {
    children: any;
}

const TabContentLeft: FunctionComponent<Props> = ({ children }) => {
    return (
        <div className={'conversation-form__content conversation-form__content--left'}>
            { children }
        </div>
    );
};

export default TabContentLeft;