export function toggleArrayValue<T>(array: T[], value: T) {
    const index = array.findIndex(v => v === value);

    if (index === -1) {
        array.push(value);
    } else {
        array.splice(index, 1);
    }

    return array;
}


export function convertDateToDutchFormat(date: string) {
    let newDate = new Date(Date.parse(date))
    let day = newDate.getDate()
    let month = newDate.getMonth() + 1
    let year = newDate.getFullYear()

    return day + '-' + month + '-' + year;
}
