import React, { FunctionComponent } from 'react';
import Page from '../../layout/Page';

import logoRegular from 'assets/logos/logo-regular.png';

const Loader: FunctionComponent = () => {

    return (
        <Page className={'loader-page'}>
            <img src={logoRegular} className={'loader-page__logo'} alt={'Logo'} />
        </Page>
    );
};

export default Loader;
