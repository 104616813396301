import ObserverRepository from '../../../networking/repos/ObserverRepository';
import {PaginationQueryConfig} from '../../query/Types';
import {EventLogIndexResponse, LoginAttemptIndexResponse} from '../../../networking/responses/Observer';
import {useIndexQueryConfig} from '../../query/Utils';

export const useEventIndexQueryConfig = (): PaginationQueryConfig<EventLogIndexResponse> => {
    const repo = new ObserverRepository();

    return useIndexQueryConfig(['events'], (page) => repo.getEvents(page));
};

export const useLoginAttemptIndexQueryConfig = (): PaginationQueryConfig<LoginAttemptIndexResponse> => {
    const repo = new ObserverRepository();

    return useIndexQueryConfig(['login-attempts'], (page) => repo.getLogins(page));
};
