import React, { FunctionComponent } from 'react';
import FormPart from '../../../../../networking/models/FormPart';
import { Droppable } from 'react-beautiful-dnd';
import DraggableShortcut from './DraggableShortcut';

interface Props {
    children: any;
    parts: FormPart[];
}

const ShortcutDroppable: FunctionComponent<Props> = ({ parts, children }) => {

    return (
        <Droppable droppableId={'shortcuts'}>
            {(provided) => (
                <div ref={provided.innerRef} { ...provided.droppableProps }>
                    {parts.map((part, index) => (
                        <DraggableShortcut part={part} index={index} key={part.id!} />
                    ))}

                    { provided.placeholder }

                    { children }
                </div>
            )}
        </Droppable>
    );
};

export default ShortcutDroppable;
