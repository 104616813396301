import React, { KeyboardEvent, ReactElement, useEffect, useRef, useState} from 'react';
import {mapChildren} from 'buro-lib-ts';

import TabContentRight from '../../pages/conversations/form/TabContentRight';

import Box, { BoxData } from './Box';
import BoxHeader from './BoxHeader';
import BoxListContextProvider from '../../../contexts/BoxListContext';
import {useTheme} from '../../../contexts/ThemeContext';

export interface ChildFilter {
    isDesiredElement: (child: any) => boolean;
    filterChild: (child: any) => ReactElement;
}

interface Props {
    children: any;
    renderId?: number;
    className?: string;
    onKeyRelease?: (e: KeyboardEvent<HTMLDivElement>) => void;
    onLoad?: (boxData: BoxData[]) => void;
    onShortcutClick?: (index: number) => void;
}

const BoxList = React.forwardRef<HTMLDivElement, Props>(({ renderId, className, children, onKeyRelease, onShortcutClick, onLoad }, ref: any ) => {
    const { theme } = useTheme();

    const [boxPositions, setBoxPositions] = useState<BoxData[]>([]);
    const [currentBox, setCurrentBox] = useState<number>(0);
    const tabRef = useRef<HTMLDivElement>(null);
    let content: HTMLDivElement | null = null;

    const positions: BoxData[] = [];

    useEffect(() => {
        tabRef.current?.focus();

        if(onLoad) {
            onLoad(positions);
        } else {
            setBoxPositions(positions);
        }
    }, []);

    const isDuplicatePosition = (pos: BoxData) => {
        return positions.findIndex(p => p.position === pos.position) !== -1;
    };

    const setBoxPosition = (data: BoxData) => {
        if(isDuplicatePosition(data)) return;

        positions.push(data);

        if(onLoad) {
            onLoad(positions);
        } else {
            setBoxPositions(positions);
        }
    };

    const scroll = (index: number) => {
        if(onShortcutClick) {
            onShortcutClick(index);
        }

        if(!content) {
            return;
        }

        const position = boxPositions[index].position;

        setCurrentBox(index);

        content.scrollTo(0, position - content.offsetTop);
    };

    const isDesiredChild = (child: ReactElement) => {
        return child.type === TabContentRight;
    };

    const parsedChildren = mapChildren(children, isDesiredChild, (child) => {
        let index = 0;

        const boxProps: any = {
            ...child.props,
            children: mapChildren(child.props.children, child => child.type === Box, (child) => {
                return React.cloneElement(child, {
                    onLoad: setBoxPosition,
                    renderId,
                    children: mapChildren(child.props?.children, child => child.type === BoxHeader, (child) => {

                        return React.cloneElement(child, { ...child.props, active: index++ === currentBox });
                    })
                });
            })
        };

        if(!onShortcutClick) {
            boxProps.ref = (node: HTMLDivElement) => content = node;
        }

        return React.cloneElement(child, boxProps);
    });

    return (
        <BoxListContextProvider scrollTo={scroll} onBoxLoad={setBoxPosition} renderId={renderId ?? 0}>
            <div className={`box-list box-list-form--${theme.modifier} ${className ? className : ''}`} onKeyUp={onKeyRelease} tabIndex={0} ref={ref}>
                { parsedChildren }
            </div>
        </BoxListContextProvider>
    );
});

export default BoxList;
