import React, { FunctionComponent } from 'react';
import {ReactComponent as CloseIcon} from '../../../../assets/icons/close.svg';

export interface ModalStyle {
    height?: string;
    width?: string;
}

interface Props {
    children: any;
    onCloseClick: () => void;
    style?: ModalStyle;
}

const ModalBody: FunctionComponent<Props> = ({ children, onCloseClick, style }) => {

    return (
        <div className={'modal__card-container'} style={style}>
            <div className={'modal__card'}>
                <div className={'modal__header'}>
                    <CloseIcon  className={'icon-grey icon-grey--filled modal__close'} onClick={onCloseClick} />
                </div>

                <div className={'modal__content'}>
                    { children }
                </div>
            </div>
        </div>
    );
};

export default ModalBody;
