import {useMutation} from '@tanstack/react-query';

import FormPartRepository from '../../../networking/repos/FormPartRepository';
import FormPart from '../../../networking/models/FormPart';
import {useFormPartInvalidator} from './Query';

export const useFormPartCreateMutation = (formId: number) => {
    const invalidateFormParts = useFormPartInvalidator(formId);
    const repo = new FormPartRepository(formId);

    return useMutation({
        mutationFn: (part: FormPart) => repo.save(part),
        onSuccess: invalidateFormParts
    });
}

export const useFormPartDeleteMutation = (formId: number) => {
    const invalidateFormParts = useFormPartInvalidator(formId);
    const repo = new FormPartRepository(formId);

    return useMutation({
        mutationFn: (part: FormPart) => repo.destroy(part),
        onSuccess: invalidateFormParts
    });
};
