import React, {FunctionComponent} from 'react';

import DevUser from './DevUser';
import {DevCredentials, } from '../../../helpers/DevTool';

interface Props {
    users: DevCredentials[];
}

const DevUsers: FunctionComponent<Props> = ({ users }) => {

    return (
        <div className={'dev-tool__users'}>
            { users.map(user => (
                <DevUser user={user} key={user.email} />
            )) }
        </div>
    );
};

export default DevUsers;
