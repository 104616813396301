import React, { FunctionComponent } from 'react';

import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg';

import AuditCall from 'networking/models/AuditCall';

import { getFormattedTime } from '../../../../helpers/Time';
import {useTheme} from '../../../contexts/ThemeContext';

interface Props {
    number: number;
    auditCall: AuditCall;
    onClick: (conversation: AuditCall) => void;
}

const Conversation: FunctionComponent<Props> = ({ number, auditCall, onClick }) => {
    const { theme } = useTheme();

    const onConversationClick = () => {
        onClick(auditCall);
    };

    const classStatus = auditCall.completed_at ? 'approved' : 'todo';
    const auditCallStatus = auditCall.completed_at ? 'Done' : 'To Do';

    return (
        <div onClick={onConversationClick} className={`item-list__grid item item--${theme.modifier}`}>
            <span className={'item-list__text'}>{ number }</span>
            <span className={`item__status item__status--${classStatus}`}>{ auditCallStatus }</span>
            <span className={'item-list__text'}>{ auditCall.created_at }</span>
            <span className={'item-list__text'}>{ getFormattedTime(auditCall.duration) }</span>
            {/*<span className={'conversation__text'}>{ auditCall.number_of_failed_knockouts }</span>*/}
            <span>
                <ArrowIcon className={'item__icon icon-grey--filled'} />
            </span>
        </div>
    );
};

export default Conversation;
