import {useMutation} from '@tanstack/react-query';

// Mutation
import FormQuestionRepository from '../../../networking/repos/FormQuestionRepository';
import Question from '../../../networking/models/Question';
import {useFormPartInvalidator} from '../FormPart/Query';

export interface FormQuestionRequest {
    partId: number;
    question: Question;
}

export const useFormQuestionCreateMutation = (formId: number) => {
    const invalidateFormParts = useFormPartInvalidator(formId);

    return useMutation({
        mutationFn: (request: FormQuestionRequest) => {
            const repo = new FormQuestionRepository(formId, request.partId);

            return repo.save(request.question);
        },
        onSuccess: invalidateFormParts
    });
};

export const useFormQuestionDeleteMutation = (formId: number) => {
    const invalidateFormParts = useFormPartInvalidator(formId);

    return useMutation({
        mutationFn: (request: FormQuestionRequest) => {
            const repo = new FormQuestionRepository(formId, request.partId);

            return repo.destroy(request.question);
        },
        onSuccess: invalidateFormParts
    });
};
