import React, { FunctionComponent } from 'react';
import {useSideBar} from '../contexts/SideBarContext';

interface Props {
    children: any;
}

const Content: FunctionComponent<Props> = ({ children }) => {
    const { menuOpen } = useSideBar();

    return (
        <div className={'content content--' + (menuOpen ? 'big' : 'small')}>
            <main>
                { children }
            </main>
        </div>
    );
};

export default Content;
