import CenterRepository from '../../../networking/repos/CenterRepository';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {CenterForm} from '../../form/Center';
import {CenterRequest} from '../../../networking/requests/Center';
import {useQueryDataSetterActions} from '../Utils';
import Form from '../../../networking/models/Form';

export const useCenterCreateMutation = () => {
    const repo = new CenterRepository();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (center: CenterForm) => repo.create({ ...center, audit_term: Number(center.audit_term) }),
        onSuccess: () => queryClient.invalidateQueries(['centers']) // TODO: Test working
    });
};

export const useCenterUpdateMutation = (id: number) => {
    const queryClient = useQueryClient();
    const repo = new CenterRepository();

    return useMutation({
        mutationFn: (request: CenterRequest) => repo.update(id, request),
        onSuccess: (response) => queryClient.setQueryData(['center-detail', id], [response])
    });
};

export const useCenterDeleteMutation = (id: number) => {
    const repo = new CenterRepository();
    const { deleteData } = useQueryDataSetterActions(['center-detail', id]);

    return useMutation({
        mutationFn: () => repo.delete(id),
        onSuccess: deleteData
    });
};

export const useCenterAnonymousAuditorsMutation = (id: number) => {
    const queryClient = useQueryClient();
    const repo = new CenterRepository();

    return useMutation({
        mutationFn: () => repo.setAnonymousAuditors(id),
        onSuccess: (response) => queryClient.setQueryData(['center-detail', id], [response])
    });
};

export const useCenterAttachFormsMutation = (id: number) => {
    const repo = new CenterRepository();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (forms: Form[]) => repo.attachCenterToForms(id, forms),
        onSuccess: () => queryClient.invalidateQueries(['center-forms'])
    });
};
