import { PaginationData, Repository, RequestMethod } from 'buro-lib-ts';
import UserFeedback from 'networking/models/UserFeedback';
import { UserFeedbackCreateRequest, UserFeedbackIndexRequest } from 'networking/requests/UserFeedback';

class UserFeedbackRepository extends Repository {

    constructor() {
        super('/user-feedback');
    }

    all(request?: UserFeedbackIndexRequest) {
        return this.requestWithOptions<UserFeedback[]>(this.url(), RequestMethod.GET, {
            params: {...request}
        }).sendPagination();
    }

    create(request: UserFeedbackCreateRequest) {
        return this.request(this.url(), RequestMethod.POST, request).send();
    }

    update(id: number, completed: boolean) {
        return this.request(this.url(`/${id}`), RequestMethod.PUT, {
            completed: completed
        }).send();
    }
}

export default UserFeedbackRepository;