import SuperAdminRepository from '../../../networking/repos/SuperAdminRepository';
import {useIndexQueryConfig} from '../../query/Utils';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {CenterUserRequest} from '../../../networking/requests/CenterUser';

export const useSuperAdminIndexQueryConfig = () => {
    const repo = new SuperAdminRepository();

    return useIndexQueryConfig(['super-admins'], () => repo.all());
};

// MUTATORS
type UpdateAdminParam = { id: number, request: CenterUserRequest }; // TODO: aDD GENERIC type

// TODO: Add invalidate or actions
export const useSuperAdminMutations = () => {
    const queryClient = useQueryClient();
    const repo = new SuperAdminRepository();

    const onSuccess = () => queryClient.invalidateQueries(['super-admins']);

    return {
        createAdmin: useMutation({
            mutationFn: (request: CenterUserRequest) => repo.create(request),
            onSuccess
        }),
        updateAdmin: useMutation({
            mutationFn: ({ id, request }: UpdateAdminParam) => repo.update(id, request),
            onSuccess
        }),
        softDeleteAdmin: useMutation({
            mutationFn: (id: number) => repo.softDelete(id),
            onSuccess
        }),
        restoreAdmin: useMutation({
            mutationFn: (id: number) => repo.restore(id),
            onSuccess
        })
    };
};
