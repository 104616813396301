import React from 'react';
import {ClassBuilder} from 'buro-lib-ts';

import BackButton from '../../../../layout/buttons/BackButton';
import Button from '../../../../layout/buttons/Button';

interface Props {
    children: any;
    next?: () => void;
    previous?: () => void;
    nextDisabled?: boolean;
    className?: string;
    scrollable?: boolean;
}

const TabContentRight = React.forwardRef<HTMLDivElement, Props>(({ children, next, previous, nextDisabled, className, scrollable= true }, ref: any)  => {

    const getClassName = () => {
        return ClassBuilder.createClass('conversation-form__content')
            .add('conversation-form__content--right')
            .addIf(className, className)
            .build();
    };

    const getScrollContentClassName = () => {
        return ClassBuilder.createClass('conversation-form__scroll-content')
            .addIf('conversation-form__scroll-content--solid', !scrollable)
            .build();
    };

    return (
        <div className={getClassName()}>
            <div className={getScrollContentClassName()} ref={ref}>
                { children }

                { (next || previous) &&
                    <div className={'conversation-form__actions'}>
                        { previous && <BackButton onClick={previous} /> }
                        { next && <Button onClick={next} disabled={nextDisabled}>Volgende</Button> }
                    </div>
                }
            </div>
        </div>
    );
});

export default TabContentRight;
