import React, {FunctionComponent, useMemo} from 'react';

interface Props {
    criteria: string;
    questionCount: number;
}

const AuditReason: FunctionComponent<Props> = ({ criteria, questionCount }) => {
    const priorityMappings: any = {
        knockout: 'Verplicht',
        error_repeat: 'Noodzakelijk',
        desirable: 'Gewenst'
    };

    const reasonMappings: any = {
        knockout: '{questions} criteria of meer',
        error_repeat: '{questions} criteria of meer',
        desirable: 'Voldaan aan de eisen'
    };

    const reason: string = useMemo<string>(() => (
        reasonMappings[criteria].replace('{questions}', questionCount.toString())
    ), [questionCount]);

    return (
        <div className={'criteria'}>
            <div className={`criteria__block criteria__block--${criteria}`} />

            <div>
                <b className={'priority'}>{ priorityMappings[criteria] }</b>

                <br/>

                <span>{ reason }</span>
            </div>
        </div>
    );
};

export default AuditReason;
