import React, { FunctionComponent } from 'react';

interface Props {
    title: string;
    children: any;
    right?: any;
}

const DevToolSection: FunctionComponent<Props> = ({ title, children, right }) => {

    return (
        <div className={'dev-tool__section'}>
            <div className={'flex flex--justify-between flex--align-items'}>
                <h3 className={'dev-tool__title '}>{ title }</h3>

                { right && right }
            </div>

            { children }
        </div>
    );
};

export default DevToolSection;
