import React, { FunctionComponent } from 'react';

import EventLogModel from '../../../../networking/models/EventLog';
import {useTheme} from '../../../contexts/ThemeContext';

interface Props {
  log: EventLogModel;
}

const EventLog: FunctionComponent<Props> = ({ log }) => {
    const { theme } = useTheme();

    return (
        <div className={`observer observer-list__grid--events observer--${theme.modifier}`}>
            <span className={'observer__text--grey'}>{ log.id }</span>
            <span className={'observer__text--grey'}>{ log.user_email }</span>
            <span className={'observer__text--grey'}>{ log.user_center }</span>
            <span className={'observer__text--grey'}>{ log.record_id }</span>
            <span className={'observer__text--grey'}>{ log.action }</span>
            <span className={'observer__text--grey'}>{ log.table_name }</span>
            <span className={'observer__text--grey'}>{ log.date }</span>
            <span className={'observer__text--grey'}>{ log.time }</span>
        </div>
    );
};

export default EventLog;
