import React, { FunctionComponent, MouseEvent, useEffect, useRef, useState} from 'react';
import {AudioLoader} from 'buro-lib-ts';

import WayPoint from 'networking/models/WayPoint';

import AudioBar from './AudioBar';

import { getFormattedTime } from 'helpers/Time';

import CircleLoader from '../loading/CircleLoader';
import {logError} from '../../../utils/devtool/DevTool';

interface Props {
    duration: number;
    currentSeconds: number;
    audioBuffer: ArrayBuffer;
    onWaveClick: (seconds: number) => void;
    onWaveDoubleClick: (seconds: number) => void;
    wavePoints: WayPoint[];
}

const AudioWave: FunctionComponent<Props> = ({ duration, currentSeconds, audioBuffer, onWaveClick, onWaveDoubleClick, wavePoints }) => {
    const audioWaveRef = useRef<HTMLDivElement>(null);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [barData, setBarData] = useState<number[]>([]);
    const [hasError, setHasError] = useState<boolean>(false);

    let mounted: boolean;

    useEffect(() => {
        mounted = true;

        if(!audioWaveRef.current || !audioBuffer) {
            return;
        }

        const waveWidth = audioWaveRef.current.clientWidth;

        const audioLoader = new AudioLoader();
        const audioBars = Math.floor((waveWidth - 2) / 6);

        audioLoader.generateAudioBarDataFromArrayBuffer(audioBuffer,  audioBars).then((audioBarData) => {
            if(mounted) {
                setLoaded(true);
                setHasError(false);
                setBarData(audioBarData);
            }
        }).catch((e) => {
            setLoaded(true);
            setHasError(true);

            logError(e);
        });

        return function cleanup() {
            mounted = false;
        };
    }, []);

    const getClickedSeconds = (e: MouseEvent<HTMLDivElement>): number => {
        if(!audioWaveRef.current){
            return 0;
        }

        const position = audioWaveRef.current.getClientRects()[0];
        const width = audioWaveRef.current.clientWidth;

        return duration / width * (e.clientX - position.left);
    };

    const onClick = (e: MouseEvent<HTMLDivElement>) => {
        onWaveClick(
            getClickedSeconds(e)
        );
    };

    const onDoubleClick = (e: MouseEvent<HTMLDivElement>) => {
        if(!audioWaveRef.current) return;

        const position = audioWaveRef.current.getClientRects()[0];

        if((e.clientY - position.top) > audioWaveRef.current.clientHeight) {
            return;
        }

        onWaveDoubleClick(
            getClickedSeconds(e)
        );
    };

    const getIndicatorPosition = () => {
        if(audioWaveRef.current === null ){
            return;
        }

        return (audioWaveRef.current.clientWidth / duration) * currentSeconds - 3;
    };

    const getWavePointPosition = (wavePointSeconds: number): number => {
        if(audioWaveRef.current === null) return 0;

        return (audioWaveRef.current.clientWidth / duration) * wavePointSeconds - 10;
    };

    const onWavePointClick = (wavePoint: WayPoint) => {
        onWaveClick(wavePoint.seconds);
    };

    return (
        <React.Fragment>
            { !loaded &&
                <div className={'flex flex--justify-center'}>
                    <CircleLoader />
                </div>
            }

            { hasError &&
                <p>Er is een fout opgetreden tijdens het maken van de audio wave.</p>
            }

            <div className={'audio-player__wave'} ref={audioWaveRef} onClick={onClick} onDoubleClick={onDoubleClick}>
                { (!hasError && loaded) &&
                    <React.Fragment>
                        <div className={'audio-player__wave-indicator'} style={{ left: `${getIndicatorPosition()}px` }} />

                        <div className={'audio-player__wave-time audio-player__wave-time--left'}>
                            <span>{ getFormattedTime(currentSeconds) }</span>
                        </div>

                        { barData.map((data, index) => (
                            <AudioBar height={data * 70} key={index} />
                        )) }

                        <div className={'audio-player__wave-time audio-player__wave-time--right'}>
                            <span>{ getFormattedTime(duration) }</span>
                        </div>

                        { wavePoints.map((wavePoint) => (
                            <div
                                className={`audio-player__wave-point audio-player__wave-point--wave  audio-player__wave-point--${!wavePoint.comment ? 'transparent' : ''}`}
                                style={{ left: `${getWavePointPosition(wavePoint.seconds)}px` }}
                                key={wavePoint.index}
                                onClick={(e) => { e.stopPropagation(); onWavePointClick(wavePoint); }}>
                                {wavePoint.index}
                            </div>
                        )) }
                    </React.Fragment>
                }
            </div>
        </React.Fragment>
    );
};

export default AudioWave;
