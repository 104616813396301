import React, { FunctionComponent } from 'react';

import EventLog from './EventLog';
import EventLogModel from '../../../../networking/models/EventLog';
import {useTheme} from '../../../contexts/ThemeContext';

interface Props {
  logs: EventLogModel[];
}

const LogsList: FunctionComponent<Props> = ({ logs }) => {
    const { theme } = useTheme();

    return (
        <div className={`observer-list observer-list--${theme.modifier}`}>
            <div className={'observer-list__header observer-list__grid--events'}>
                <span className="observer-list__title">Log ID</span>
                <span className="observer-list__title">Gebruiker</span>
                <span className="observer-list__title">Meldkamer</span>
                <span className="observer-list__title">Item ID</span>
                <span className="observer-list__title">Actie</span>
                <span className="observer-list__title">Soort</span>
                <span className="observer-list__title">Datum</span>
                <span className="observer-list__title">Tijd</span>
            </div>

            <div className="observer-list__login-attempts">
                {logs.map((logItem: any, index: number) => (
                    <EventLog log={logItem} key={index}/>
                ))}
            </div>
        </div>
    );
};

export default LogsList;
