import {CenterUserRequest} from '../../../networking/requests/CenterUser';
import {useQueryDataSetterActions} from '../Utils';
import UserRepository from '../../../networking/repos/UserRepository';
import {useMutation} from '@tanstack/react-query';

type UpdateUserParam = { id: number, request: CenterUserRequest }; // TODO: make global UpdateParam type with <T> & id field

export const useUserCollectionMutations = (center: number) => {
    const { addData, updateData, deleteData } = useQueryDataSetterActions(['users']);
    const repo = new UserRepository(center);

    return {
        createUser: useMutation({
            mutationFn: (request: CenterUserRequest) => {
                return repo.create(request)},
            onSuccess: addData
        }),
        updateUser: useMutation({
            mutationFn: ({ id, request }: UpdateUserParam) => repo.update(id, request),
            onSuccess: updateData
        }),
        softDeleteUser: useMutation({
            mutationFn: (id: number) => repo.softDelete(id),
            onSuccess: deleteData
        }),
        restoreUser: useMutation({
            mutationFn: (id: number) => repo.restore(id),
            onSuccess: updateData
        })
    };
};
