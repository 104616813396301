import {UseQueryResult, useQuery} from '@tanstack/react-query';

import FormRepository from '../../../networking/repos/FormRepository';
import Form from '../../../networking/models/Form';
import {QueryConfig} from '../Types';
import {FormIndexResponse} from '../../../networking/responses/Form';
import {ScoreFilter} from '../Center/Query';

export interface FormStatistics {
    months: string[];
    scoresD: number[];
    scoresN: number[];
    scoresK: number[];
}

export interface AvailableYears {
    years: string[];
}

export type FormQueryPaginationResult = UseQueryResult<FormIndexResponse>;

export const useFormIndexQueryConfig = () => {
    const repo = new FormRepository();

    return [{
        queryKey: ['forms'],
        queryFn: () => repo.all()
    }];
};

export const useFormFinalQuery = () => {
    const repo = new FormRepository();

    return useQuery({
        queryKey: ['forms', 'final'],
        queryFn: () => repo.final(),
    });
};

export const useFormForCenterQueryConfig = (center: number) => {
    const repo = new FormRepository();

    return [{
        queryKey: ['center-forms', center],
        queryFn: () => repo.getCenterForms(center)
    }];
};

export const useFormDetailQueryConfig = (form: number): [QueryConfig<Form>] => {
    const repo = new FormRepository();

    return [{
        queryKey: ['form-detail', form],
        queryFn: () => repo.find(form),
    }];
};

export const useFormEntranceComplaintsQueryConfig = () => {
    const repo = new FormRepository();

    return [{
        queryKey: ['forms', 'complaints'],
        queryFn: () => repo.getEntranceComplaints()
    }];
};

export const useFormCenterFormsQueryConfig = (centerId: number) => {
    const repo = new FormRepository();

    return [{
        queryKey: ['forms', 'center', centerId],
        queryFn: () => repo.getCenterForms(centerId)
    }];
};

export const useFormAvailableYearsQueryConfig = (centerId: number, filters: ScoreFilter) : [QueryConfig<AvailableYears>] => {
    const repo = new FormRepository();

    return [
        {
            queryKey: ['forms', filters.form?.id, 'center', centerId, 'years'],
            queryFn: async ()   => {
                if(!filters.form) {
                    return {
                        years: []
                    }
                }
                return await repo.getAvailableYears(filters.form.id, centerId);
            }
        }
    ]
}

export const useFormStatisticsQuery = (centerId: number, filters: ScoreFilter): [QueryConfig<FormStatistics>] => {
    const repo = new FormRepository();

    return [{
        queryKey: ['forms', 'statistics', centerId, filters],
        queryFn: async () => {
            if(!filters.form || !filters.year) {
                return {
                    months: [],
                    scoresN: [],
                    scoresD: [],
                    scoresK: []
                };
            }

            const scores = await repo.getStatistics(filters.year, filters.form.id, centerId);

            let months:string[] = [];
            let scoresD:number[] = [];
            let scoresN:number[] = [];
            let scoresK:number[] = [];

            scores.forEach(result => {
                months.push(result.month_name);

                result.scores.forEach(score => {
                    if(score.type === 'desirable') {
                        scoresD.push(score.score);
                    }

                    if(score.type === 'necessary') {
                        scoresN.push(score.score);
                    }

                    if(score.type === 'knockout') {
                        scoresK.push(score.score);
                    }
                });
            });

            return { months, scoresD, scoresN, scoresK };
        }
    }];
};
