import {input, useForm, V} from "buro-lib-ts";
import {MeetingRequest} from "../../networking/requests/MeetingRequest";
import {Meeting} from "../../networking/models/Meeting";
import {MV} from "../../helpers/Validation";

export const useMeetingForm = (meeting?: Meeting) => {
    if(!meeting){
        return useForm<MeetingRequest>({
            name: input('', [V.required, V.size(1, 50)]),
            date: input('', [V.required, MV.dateBetween(new Date('2022-01-01'), new Date('2099-12-31'))]),
            time: input('', [V.required, MV.validTime()]),
            comment: input('', [V.size(0, 1000)])
        });
    }

    return useForm<MeetingRequest>({
        name: input(meeting.name, [V.required, V.size(1, 50)]),
        date: input(meeting.date, [V.required, MV.dateBetween(new Date('2022-01-01'), new Date('2099-12-31'))]),
        time: input(meeting.time, [V.required, MV.validTime()]),
        comment: input(meeting.comment, [V.size(0, 1000)])
    });
}
