import { Repository, RequestMethod} from 'buro-lib-ts';

import Dashboard from '../models/Dashboard';

class DashboardRepository extends Repository {

    public constructor() {
        super('/dashboard');
    }

    public getDashboard(month: string): Promise<Dashboard> {
      return this.request<Dashboard>(this.url(`?month=${month}`), RequestMethod.GET).sendRaw();
    }
}

export default DashboardRepository;
