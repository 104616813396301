import React, { FunctionComponent } from 'react';

import { ReactComponent as PlayIcon } from 'assets/icons/audioplayer/play.svg';
import { ReactComponent as PauseIcon } from 'assets/icons/audioplayer/pause.svg';
import { ReactComponent as ForwardIcon } from 'assets/icons/audioplayer/forward10sec.svg';
import { ReactComponent as BackwardIcon } from 'assets/icons/audioplayer/rewind10sec.svg';
import { ReactComponent as PlayNormalSpeedIcon } from 'assets/icons/audioplayer/speedup.svg';
import { ReactComponent as PlaySlowSpeedIcon } from 'assets/icons/audioplayer/rewind.svg';

interface Props {
    onTimeAdd: () => void;
    onTimeSubtract: () => void;
    onPlayToggle: () => void;
    onPlaySpeedUpdate: (speed: number) => void;
    playing: boolean;
    playSpeed: number;
}

const PlayControls: FunctionComponent<Props> = ({ playSpeed, onTimeAdd, onTimeSubtract, onPlayToggle, onPlaySpeedUpdate, playing }) => {

    const getPlayControls = () => {
        if(!playing) {
            return <PlayIcon className={'audio-player__control-icon audio-player__control-icon--toggle'} onClick={onPlayToggle} />;
        }

        return (
            <PauseIcon className={'audio-player__control-icon audio-player__control-icon--toggle'} onClick={onPlayToggle} />
        );
    };

    const setPlaySpeedNormal = () => {
        onPlaySpeedUpdate(1);
    };

    const setPlaySpeedSlow = () => {
        onPlaySpeedUpdate(0.5);
    };

    return (
        <div className={'audio-player__play-controls audio-player__container'}>
            <div className={'audio-player__control audio-player__control--text'} onClick={onTimeSubtract}>
                <BackwardIcon className={'audio-player__control-icon'} />

                <span className={'audio-player__text-control-text audio-player__text-control-text--absolute'}>-10</span>
            </div>


            <div className={'audio-player__control audio-player__control--space-around'} >
                <PlaySlowSpeedIcon className={'audio-player__control-icon'} onClick={setPlaySpeedSlow} />

                { getPlayControls() }

                <PlayNormalSpeedIcon className={'audio-player__control-icon'} onClick={setPlaySpeedNormal} />
            </div>


            <div className={'audio-player__control audio-player__control--text'} onClick={onTimeAdd}>
                <ForwardIcon className={'audio-player__control-icon'} />

                <span className={'audio-player__text-control-text audio-player__text-control-text--absolute'}>+10</span>
            </div>

            <div className={'audio-player__play-speed'}>
                <span className={'audio-player__text-control-text'}>Snelheid: {(playSpeed < 1) ? 'Langzaam' : 'Normaal'}</span>
            </div>
        </div>
    );
};

export default PlayControls;
