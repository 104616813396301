import React, { FunctionComponent } from 'react';

interface Props {
    className?: string;
    center?: boolean;
}

const CircleLoader: FunctionComponent<Props> = ({ className, center }) => {

    return (
        <div className={`circle-loader ${className && className} ${center ? 'circle-loader--center' : ''}`} />
    );
};

export default CircleLoader;
