import {useMutation, useQueryClient} from '@tanstack/react-query';

import FormRepository, {ReorderQuestionsRequest, ReorderRequest} from '../../../networking/repos/FormRepository';
import {FormState} from '../../form/Form';
import {createFormRequest} from '../../../networking/requests/Form';
import Form from '../../../networking/models/Form';

interface ReorderFormQuestionsRequest {
    form: Form;
    request: ReorderQuestionsRequest;
}

interface ReorderFormPartsRequest {
    form: Form;
    request: ReorderRequest;
}

export const useFormMutations = () => {
    return {
        createForm: useFormCreateMutation(),
        updateForm: useFormUpdateMutation(),
        deleteForm: useFormDeleteMutation()
    };
};

export const useFormCreateMutation = () => {
    const queryClient = useQueryClient();
    const repo = new FormRepository();

    return useMutation({
        mutationFn: (form: FormState) => repo.create(createFormRequest(form)),
        onSuccess: (form: Form) => {
            queryClient.setQueryData(['forms'], (data: any) => {
                if(!data)
                    return data;

                return [...(data as Form[]), form];
            });
        }
    })
};

export const useFormUpdateMutation = () => {
    const queryClient = useQueryClient();
    const repo = new FormRepository();

    return useMutation({
        mutationFn: (form: FormState) => repo.update(form.id!, createFormRequest(form)),
        onSuccess: () => queryClient.invalidateQueries(['forms'])
    })
};

export const useFormDeleteMutation = () => {
    const queryClient = useQueryClient();
    const repo = new FormRepository();

    return useMutation({
        mutationFn: (form: number) => repo.delete(form),
        onSuccess: (response: Form) => {
            queryClient.setQueryData(['forms'], (data: any) => {
                if(!data)
                    return data;

                const forms = [...(data as Form[])];
                const index = forms.findIndex(form => form.id === response.id);
                forms.splice(index, 1);

                return forms;
            });
        }
    })
};

// TODO: onSuccess?
export const useFormReorderQuestionsMutation = () => {
    const repo = new FormRepository();

    return useMutation({
        mutationFn: (request: ReorderFormQuestionsRequest) => repo.reorderQuestions(request.form, request.request)
    });
};

export const useFormReorderPartsMutation = () => {
    const repo = new FormRepository();

    return useMutation({
        mutationFn: (request: ReorderFormPartsRequest) => repo.reorderParts(request.form, request.request)
    });
};
