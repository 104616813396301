import React, { useEffect, useState, FunctionComponent } from 'react';
import {FormAccessor, OnFormChangeCallback} from 'buro-lib-ts';

import User from '../../../../networking/models/User';

import Input from '../../partials/input/Input';

import MessageIcon from 'assets/icons/message.svg';
import PhoneIcon from 'assets/icons/phone.svg';
import Button from '../../../layout/buttons/Button';
import {UserForm} from '../../../form/User';

interface Props {
    user: User;
    form: FormAccessor<UserForm>;
    onFormChange: OnFormChangeCallback<UserForm>;
    onSave: () => void;
}

// TODO: Test form
const AccountCard: FunctionComponent<Props> = ({ user, form, onFormChange, onSave }) => {
    const [disabled, setDisabled] = useState<boolean>(false);

    useEffect(() => {
        if(user.sso_identifier) {
            setDisabled(true);
        }
    }, [user, setDisabled]);

    return (
        <div className={'account-card'}>
            <div className={'account-card__section'}>
                <p className={'account-card__title'}>Gegevens</p>
                <p className={'account-card__sub-title'}>Pas hier je gegevens aan</p>
            </div>

            <div className={'account-card__section'}>
                <div className={'account-card__input-grid'}>
                    <Input id={'first_name'} form={form} onChange={onFormChange} text={'Voornaam'} disabled={disabled} />
                    <Input id={'last_name'} form={form} onChange={onFormChange} text={'Achternaam'} disabled={disabled} />
                </div>
            </div>

            <div className={'account-card__section'}>
                <div className={'account-card__input-grid'}>
                    <Input id={'email'} form={form} onChange={onFormChange} text={'E-mail'} icon={MessageIcon} disabled={disabled} />
                    <Input id={'phone'} form={form} onChange={onFormChange} text={'Telefoonnummer'} icon={PhoneIcon} disabled={disabled} />
                </div>
            </div>

            <div className={'account-card__section'}>
                <p className={'account-card__text account-card__text--title'}>Functies</p>

                <div className={'flex'}>
                    { user.roles.map((role, index) => (
                        <p className={'account-card__text account-card__text--spaced'} key={index}>{ role.name }</p>
                    ))}
                </div>
            </div>

            <div className={'account-card__section'}>
                <div className={'flex flex--justify-between'}>
                    <Button onClick={onSave} disabled={disabled}>Opslaan</Button>
                </div>
            </div>
        </div>
    );
};

export default AccountCard;
