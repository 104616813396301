import React, { FunctionComponent, MouseEvent, useRef, useState} from 'react';

import { ReactComponent as SoundLowIcon } from 'assets/icons/audioplayer/sound-low.svg';
import { ReactComponent as SoundHighIcon } from 'assets/icons/audioplayer/sound-loud.svg';

interface Props {
    onVolumeUpdate: (percentage: number) => void;
}

const VolumeBar: FunctionComponent<Props> = ({ onVolumeUpdate }) => {
    const volumeBarRef = useRef<HTMLDivElement>(null);

    const [volume, setVolume] = useState<number>(1);

    const volumeBarClick = (e: MouseEvent<HTMLDivElement>) => {
        if(volumeBarRef.current === null) {
            return 1;
        }

        const barPosition = volumeBarRef.current.getClientRects()[0];
        const width = volumeBarRef.current.offsetWidth;
        const newVolume = Math.max(((e.clientX - barPosition.left) / width * 100) / 100, 0);

        onVolumeUpdate(newVolume);
        setVolume(newVolume);
    };

    const getWidth = (): number => {
        return volume * 100;
    };

    const mute = () => {
        onVolumeUpdate(0);
        setVolume(0);
    };

    const fullVolume = () => {
        onVolumeUpdate(1);
        setVolume(1);
    };

    return (
        <div className={'audio-player__volume-container'}>
            <SoundLowIcon className={'audio-player__volume-icon'} onClick={mute} />

            <div className={'audio-player__volume'} onClick={volumeBarClick} ref={volumeBarRef}>
                <div className={'audio-player__volume-bar'} style={{ width: `${getWidth()}%` }} />
            </div>

            <SoundHighIcon className={'audio-player__volume-icon audio-player__volume-icon--big'} onClick={fullVolume} />
        </div>
    );
};

export default VolumeBar;