import { Repository, RequestMethod} from 'buro-lib-ts';

interface RoleStatistic {
    name: string;
    count: number;
}

export interface UserStatistics {
    total: number;
    roles: RoleStatistic[];
}

class StatisticsRepository extends Repository {
    constructor(centerId: number) {
        super(`/centers/${centerId}/statistics`);
    }

    getUserStatistics() {
        return this.request<UserStatistics>(this.url('/user'), RequestMethod.GET).sendRaw();
    }
}

export default StatisticsRepository;
