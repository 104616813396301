import React, { FunctionComponent } from 'react';
import {useTheme} from '../../contexts/ThemeContext';

interface Props {
    icon: FunctionComponent<React.SVGProps<SVGSVGElement>>;
    children?: any;
    onClick: () => void;
}

const IconButton: FunctionComponent<Props> = ({ icon, children, onClick }) => {
    const { theme } = useTheme();

    const Icon = icon;

    return (
        <button className={'button icon-button icon-button--' + theme.modifier + ' ' + (children === undefined ? 'icon-button--textless' : '')} onClick={onClick}>
            <Icon className={'icon-white icon-white--filled icon-button__icon'} />
            {children && 
                <span>{ children }</span>
            }
        </button>
    );
};

export default IconButton;
