import { useQuery } from '@tanstack/react-query';
import UserFeedbackRepository from 'networking/repos/UserFeedbackRepository';
import { UserFeedbackIndexRequest } from 'networking/requests/UserFeedback';

export const useUserFeedbackIndexQuery = (request?: UserFeedbackIndexRequest) => {
    const repo = new UserFeedbackRepository();

    return useQuery({
        queryKey: ['user-feedback', request],
        queryFn: () => repo.all(request),
        keepPreviousData: true
    })
};