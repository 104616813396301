import {ModelRepository} from 'buro-lib-ts';

import FormPart from '../models/FormPart';

class FormPartRepository extends ModelRepository<FormPart> {

    public constructor(form: number) {
        super(`/forms/${form}/parts`);
    }

}

export default FormPartRepository;
