import React, {FunctionComponent} from 'react';

import {useBoxList} from '../../../contexts/BoxListContext';
import {useTheme} from '../../../contexts/ThemeContext';

interface Props {
    title?: string | number;
    children?: any;
    active?: boolean;
    desc: string;
    index: number;
}

const BoxShortcut: FunctionComponent<Props> = ({ title, desc, children, active, index  }) => {
    const { theme } = useTheme();

    const { activeBox, scrollToBox } = useBoxList();

    const isActive = active ?? index === activeBox;

    const onShortcutClick = () => {
        scrollToBox(index);
    };

    return (
        <div className={`box-shortcut box-shortcut--${theme.modifier} ${isActive && 'box-shortcut--active'}`} onClick={onShortcutClick}>
            <div className={'box-shortcut__content box-shortcut__content--left'}>
                <span className={'box-shortcut__text box-shortcut__text--top'}>{title}</span>
                <span className={'box-shortcut__text box-shortcut__text--bottom'}>{ desc }</span>
            </div>

            { children }
        </div>
    );
};

export default BoxShortcut;
