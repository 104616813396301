import React, { FunctionComponent } from 'react';

import CheckIcon from '../../../utils/CheckIcon';
import CrossIcon from '../../../utils/CrossIcon';

import LoginAttemptModal from '../../../../networking/models/LoginAttempt';
import {useTheme} from '../../../contexts/ThemeContext';

interface Props {
  login: LoginAttemptModal;
}

const LoginAttempt: FunctionComponent<Props> = ({ login }) => {
    const { theme } = useTheme();

    return (
        <div className={`observer observer-list__grid--logins observer--${theme.modifier}`}>
            <span className={'observer__text--grey'}>{ login.id }</span>
            <span className={'observer__text--grey'}>{ login.user_id }</span>
            <span className={'observer__text--grey'}>{ login.email }</span>
            <span className={'observer__text--grey'}>{ login.user_center }</span>
            <span className={'observer__text--grey'}>{ login.ip_address}</span>
            <span className={'observer__text--grey'}>{ login.date }</span>
            <span className={'observer__text--grey'}>{ login.time }</span>
            <span className={'observer__text--grey'}>{ (login.successful) ? <CheckIcon /> : <CrossIcon /> }</span>
        </div>
    );
};

export default LoginAttempt;

