import {ModelRepository, RequestMethod} from 'buro-lib-ts';

import Question from '../models/Question';
import AuditCall from "../models/AuditCall";

class FormQuestionRepository extends ModelRepository<Question> {

    public constructor(form: number, part: number) {
        super(`/forms/${form}/parts/${part}/questions`);
    }


    public uploadFile(question: number, data: FormData) : Promise<any> {
        return this.requestWithOptions<any>(this.eUrl(question, '/upload-file'), RequestMethod.POST, {
            data: data,
            responseType: 'json',
        }).send();
    }

    public deleteFile(question: number) : Promise<any> {
        return this.requestWithOptions<any>(this.eUrl(question, '/delete-file'), RequestMethod.DELETE, {

        }).send();
    }


}

export default FormQuestionRepository;
