export interface Theme {
    name: string;
    modifier: string;
    isDark: boolean;
}

export const DarkTheme: Theme = {
    name: 'light-theme',
    modifier: 'dark',
    isDark: true
};

export const LightTheme: Theme = {
    name: 'dark-theme',
    modifier: 'light',
    isDark: false
};

export const themes = [LightTheme, DarkTheme];

