import DashboardRepository from '../../../networking/repos/DashboardRepository';

export const useDashboardQueryConfig = (month: string) => {
    const repo = new DashboardRepository();

    return [{
        queryKey: ['dashboard', month],
        queryFn: () => repo.getDashboard(month)
    }];
};
