export const parseTime = (totalSeconds: number) => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = Math.round((totalSeconds - minutes) % 60);

    return {
        minutes: minutes,
        seconds: seconds
    };
};

export const parseTimePadded = (totalSeconds: number) => {
    const parsedTime = parseTime(totalSeconds);

    return {
        minutes: String(parsedTime.minutes).padStart(2, '0'),
        seconds: String(parsedTime.seconds).padStart(2, '0')
    };
};

export const getFormattedTime = (totalSeconds: number): string => {
    const timeLeft = parseTimePadded(totalSeconds);

    return `${timeLeft.minutes}:${timeLeft.seconds}`;
};

export const set112Timeout = (callback: () => void, time: number) => {
    const timeout = setTimeout(callback, time);

    return {
        timeout,
        clear: () => clearTimeout(timeout)
    };
};
