import {ModelRepository, RequestMethod} from 'buro-lib-ts';

import Form from '../models/Form';
import ScoresPerMonth from '../models/ScoresPerMonth';
import {FormRequest} from '../requests/Form';
import {AvailableYears} from "../../components/query/Form/Form";

export interface ReorderRequest {
    start: number;
    end: number;
}

export interface ReorderQuestionsRequest extends ReorderRequest {
    sourcePart: number;
    destinationPart: number;
}

export interface EntranceComplaints {
    operator: string[];
    auditor: string[];
}

class FormRepository extends ModelRepository<any> {

    public constructor() {
        super('/forms');
    }

    public final() {
        return this.request<Form[]>(this.url('/final'), RequestMethod.GET).send();
    }

    public create(request: FormRequest) {
        return this.request<Form>(this.url(), RequestMethod.POST, request).send();
    }

    public update(form: number, request: FormRequest) {
        return this.request<Form>(this.eUrl(form), RequestMethod.PUT, request).send();
    }

    public delete(form: number) {
        return this.request<Form>(this.eUrl(form), RequestMethod.DELETE).send();
    }

    public getCenterForms(centerId: number) {
        return this.request<Form[]>(this.url('/final?center_id=' + centerId), RequestMethod.GET).send();
    }

    public reorderQuestions(form: Form, request: ReorderQuestionsRequest) {
        return this.request<any>(this.eUrl(form, '/reorder-questions'), RequestMethod.POST, request).send();
    }

    public reorderParts(form: Form, request: ReorderRequest) {
        return this.request<any>(this.eUrl(form, '/reorder-parts'), RequestMethod.POST, request).send();
    }

    public getEntranceComplaints() {
        return this.request<EntranceComplaints>(this.url('/entrance-complaints'), RequestMethod.GET).send();
    }
    
    public getStatistics(year: number, formId: number, centerId: number) {
        return this.request<ScoresPerMonth[]>(this.url(`/${formId}/scores?center=${centerId}&year=${year}`), RequestMethod.GET).send();
    }

    public getAvailableYears(formId: number, centerId: number) {
        return this.request<AvailableYears>(this.url(`/${formId}/scores/years?center=${centerId}`), RequestMethod.GET).send();

    }
}

export default FormRepository;
