import React, { FunctionComponent } from 'react';
import ProgressCircle from 'components/utils/ProgressCircle';

interface Props {
    data: {
        id: number;
        first_name: string;
        last_name: string;
        total: number;
        finished: number;
    }
}

const EmployeeProgressItem: FunctionComponent<Props> = (props) => {
    return (
        <React.Fragment>
            <div className="dashboard-page__employee-progress__item">
                <div>
                    <div className="dashboard-page__employee-progress__item__name">
                        {`${props.data.first_name} ${props.data.last_name}`}
                    </div>
                </div>
                <div className="dashboard-page__employee-progress__item__score">
                    {props.data.finished}<span className="dashboard-page__employee-progress__item__score--light">:{props.data.total}</span>
                </div>

                <ProgressCircle total={props.data.total} finished={props.data.finished} />
            </div>
            <div className="dashboard-page__employee-progress__border" />
        </React.Fragment>
    );
};

export default EmployeeProgressItem;
