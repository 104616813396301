import React, { FunctionComponent } from 'react';

export interface ToggleChangedEvent {
    id?: string;
    value: boolean;
}

interface Props {
    id?: string;
    active: boolean;
    onChange: (event: ToggleChangedEvent) => void;
}

const Toggle: FunctionComponent<Props> = ({ id,active, onChange }) => {

    const onToggle = () => {
        onChange({ id, value: !active });
    };

    return (
        <label className={'switch'}>
            <input type="checkbox" onChange={onToggle} checked={active} />
            <span className="switch__slider switch__round" />
        </label>
    );
};

export default Toggle;
