import React, { FunctionComponent } from 'react';

interface Props {
    children: any;
    scrollable?: any;
}

const TabNavigationContent: FunctionComponent<Props> = ({ children, scrollable }) => {

    return (
        <div className={`tab-navigation tab-navigation__content ${scrollable ? 'tab-navigation__content--scrollable' : ''}`}>
            { children }
        </div>
    );
};

export default TabNavigationContent;