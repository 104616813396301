import {ModelRepository} from 'buro-lib-ts';

import WayPoint from '../models/WayPoint';

class WayPointRepository extends ModelRepository<WayPoint> {

    public constructor(auditCall: number) {
        super(`calls/${auditCall}/waypoints`);
    }
}

export default WayPointRepository;
