import React, { FunctionComponent } from 'react';
import Form from '../../../../networking/models/Form';
import AuditForm from './AuditForm';
import {useTheme} from '../../../contexts/ThemeContext';

interface Props {
    forms: Form[];
    onFormClick: (form: Form) => void;
    onFormEditClick: (form: Form) => void;
    setSelectedForm: Function
    toggleDeleteModal: Function
}

const AuditFormList: FunctionComponent<Props> = ({ forms, onFormClick, onFormEditClick, setSelectedForm, toggleDeleteModal }) => {
    const { theme } = useTheme();

    return (
        <div className={`audit-form-list audit-form-list--${theme.modifier}`}>
            <div className={'audit-form-list__header audit-form-list__grid'}>
                <span className={'audit-form-list__title'}>Naam</span>
                <span className={'audit-form-list__title'}>Meldkamers</span>
                <span className={'audit-form-list__title'}>Audits</span>
                <span className={'audit-form-list__title'}>Max noodzakelijk</span>
                <span className={'audit-form-list__title'}>Max verplicht</span>
                <span className={'audit-form-list__title'}>Aangemaakt</span>
                <span className={'audit-form-list__title'}>Laatst aangepast</span>
                <span className={'audit-form-list__title'}>Actie</span>
            </div>

            <div className={'audit-form-list__forms'}>
                { forms.map((form) => (
                    <AuditForm
                        form={form}
                        onClick={onFormClick}
                        onEditClick={onFormEditClick}
                        key={form.id}
                        setSelectedForm={setSelectedForm}
                        toggleDeleteModal={toggleDeleteModal} />
                )) }
            </div>
        </div>
    );
};

export default AuditFormList;
