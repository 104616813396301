import React, { FunctionComponent } from 'react';

import AuditModel from 'networking/models/Audit';
import Audit from './Audit';
import {useTheme} from '../../../contexts/ThemeContext';

interface Props {
    audits: AuditModel[];
    withAuditor?: boolean;
    withReleasedButtons?: boolean;
    onShowMoreClick?: () => void;
}

const AuditList: FunctionComponent<Props> = ({ audits, withAuditor, withReleasedButtons, onShowMoreClick }) => {
    const { theme } = useTheme();

    return (
        <div className={`audit-list audit-list--${theme.modifier}`}>
            <div className={`audit-list__header audit-list__grid${withAuditor ? '--with-auditor' : ''} audit-list__grid${withReleasedButtons ? '--with-released' : ''}`}>
                <span className="audit-list__title">ID</span>
                {withReleasedButtons &&
                    <span className="audit-list__title">weergave</span>
                }
                <span className="audit-list__title">status</span>
                <span className="audit-list__title">Formulier</span>
                {withAuditor &&
                    <span className="audit-list__title">Auditor</span>
                }
                <span className="audit-list__title">Triagist</span>
                <span className="audit-list__title">Gemaakt op</span>
                <span className="audit-list__title">Voortgang</span>
                <span className="audit-list__title">Ingezien</span>
            </div>

            <div className="audit-list__audits">
                {audits.map((audit) => (
                    <Audit withAuditor={withAuditor} withReleasedButtons={withReleasedButtons} audit={audit} key={audit.id} />
                ))}

                { onShowMoreClick &&
                    <div className={'flex flex--justify-center'}>
                        <p className={'edit-text'} onClick={onShowMoreClick}>Toon meer</p>
                    </div>
                }
            </div>
        </div>
    );
};

export default AuditList;
