import React, {FunctionComponent} from 'react';
import { motion } from 'framer-motion';

interface Props {
    children: any;
    className?: string;
    scrollable?: any;
}

const pageVariants = {
    hidden: {
        opacity: 0
    },
    enter: {
        opacity: 1
    },
    exit: {
        opacity: 0,
        transition: {
            duration: .1,
            ease: 'easeInOut'
        }
    }
};

const Page: FunctionComponent<Props> = ({ children, className, scrollable }) => {
    return (
        <motion.section className={`page ${scrollable ? 'page--scrollable' : ''} ${className ?? ''}`} variants={pageVariants} initial={'hidden'} animate={'enter'} exit={'exit'}>
            { children }
        </motion.section>
    );
};

export default Page;
