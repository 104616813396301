import {useQueryDataSetterActions} from '../Utils';
import {useMutation} from '@tanstack/react-query';
import MeetingRepository from "../../../networking/repos/MeetingRepository";
import {MeetingRequest} from "../../../networking/requests/MeetingRequest";

type UpdateMeetingParam = { id: number, request: MeetingRequest };

export const useMeetingMutation = (auditId : number) => {
    const { addData, updateData, deleteData } = useQueryDataSetterActions(['meetings-' + auditId]);
    const repo = new MeetingRepository(auditId);

    return {
        createMeeting: useMutation({
            mutationFn: (request: MeetingRequest) => repo.create(request),
            onSuccess: addData
        }),
        updateMeeting: useMutation({
            mutationFn: ({ id, request }: UpdateMeetingParam) => repo.update(id, request),
            onSuccess: updateData
        }),
        deleteMeeting: useMutation({
            mutationFn: (id : number) => repo.delete(id),
            onSuccess: deleteData
        }),
    };
};
