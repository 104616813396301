

export enum Env {
    PROD = 'prod', DEV = 'dev'
}

export const env = (key: string) => {
    if(!process.env[key]) {
        throw new Error(`Environment variable with key ${key} does not exist!`);
    }

    return process.env[key];
};

export const appEnv = (): Env => {
    if(process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === Env.DEV) {
        return Env.DEV;
    }

    return Env.PROD;
};

export const getEnvUrl = () => process.env.REACT_APP_API_ENDPOINT ?? '';

export const isDev = () => {
    const apiEndpoint = process.env.REACT_APP_API_ENDPOINT ?? '';
    return !apiEndpoint.includes('meldkamer-coach.nl');
}
