import React, {FunctionComponent,} from 'react';

import Question from '../../../../../networking/models/Question';

import {ReactComponent as InfoIcon} from 'assets/icons/info.svg';
import {useTheme} from '../../../../contexts/ThemeContext';

interface Props {
    question: Question;
    children: any;
    active?: boolean;
    showType?: boolean;
}

const FormQuestion: FunctionComponent<Props> = ({question, children, active, showType}) => {
    const {theme} = useTheme();

    showType = showType !== false;

    return (
        <div className={'form-question__indicator-container'}>
            <div className={'form-question__indicator'}/>
            <div
                className={`form-question form-question--${theme.modifier} ${active && 'form-question--open'} ${showType && `form-question--${question.type}`}`}>
                <div className={'form-question__info-container'}>
                    <InfoIcon className={'form-question__icon'}/>

                    <div className={'form-question__explanation-box'}>
                        {question.image &&
                            <img src={question.image} alt={question.question} className={'form-question__image'}/>
                        }
                        <p dangerouslySetInnerHTML={{__html: question.explanation.replace(/(?:\r\n|\r|\n)/g, '<br>')}}/>
                    </div>
                </div>
                <span className={'form-question__question'}>{question.number}. {question.question}</span>

                {children}
            </div>
        </div>
    );
};

export default FormQuestion;
