import {ModelRepository, RequestMethod} from 'buro-lib-ts';

import {Meeting} from "../models/Meeting";
import {MeetingRequest} from "../requests/MeetingRequest";

class MeetingRepository extends ModelRepository<Meeting> {

    public constructor(auditId: number) {
        super('/audits/' + auditId + '/meetings');
    }

    public getAll(): Promise<Meeting[]> {
        return this.request<Meeting[]>(this.url(`/`), RequestMethod.GET).send();
    }

    public create(request : MeetingRequest) : Promise<Meeting> {
        return this.request<Meeting>(this.url(`/`), RequestMethod.POST, {...request}).send();
    }

    public update(meetingId: number, request : MeetingRequest) : Promise<Meeting> {
        return this.request<Meeting>(this.url(`/${meetingId}`), RequestMethod.PUT, {...request}).send();
    }

    public delete(meetingId: number) : Promise<Meeting> {
        return this.request<Meeting>(this.url(`/${meetingId}`), RequestMethod.DELETE).send();
    }
}

export default MeetingRepository;
