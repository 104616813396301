import {UseQueryResult} from '@tanstack/react-query';

import CenterRepository from '../../../networking/repos/CenterRepository';
import {useIndexQueryConfig} from '../Utils';
import {CenterDetailResponse} from '../../../networking/responses/Center';
import Form from '../../../networking/models/Form';
import ScoresPerQuestion from '../../../networking/models/ScoresPerQuestion';

export type CenterDetailQueryResult = UseQueryResult<CenterDetailResponse>;

export interface ScoreFilter {
    form?: Form;
    year?: number;
    criteriumScore: ScoresPerQuestion | undefined
}

export const useCenterIndexQueryConfig = () => {
    const repo = new CenterRepository();

    // TODO: Custom repo.all
    return useIndexQueryConfig(['centers'], () => repo.all());
};

// TODO: Test and check maybe response[0] must be used
export const useCenterDetailQueryConfig = (id: number) => {
    const repo = new CenterRepository();

    return [{
        queryKey: ['center-detail', id],
        queryFn: () => repo.find(id)
    }];
};

export const useCenterScoresPerFormQueryConfig = (centerId: number, filters: ScoreFilter) => {
    const repo = new CenterRepository();

    return [{
        queryKey: ['center-scores', centerId, filters.form?.id],
        queryFn: () => {
            return filters.form
                ? repo.getCenterScoresPerForm(centerId, filters.form.id)
                : [];
        }
    }];
};

export const useCentersSsoQueryConfig = () => {
    const repo = new CenterRepository();

    return useIndexQueryConfig(['centers-sso'], () => repo.getCentersSso());
}
