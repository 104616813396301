import {Repository, RequestError, RequestMethod} from 'buro-lib-ts';

import Credentials from '../models/Credentials';
import User from '../models/User';
import UserGuides from '../models/UserGuides';

export interface LoginResponse {
    user: User,
    token: Token
}

export interface GetUserResponse {
    user: GetUser;
    stages: Stages;
}

export interface Get2FAQRResponse {
    base64: string;
}

export interface TokenResponse {
    access_token: string,
    expires_in: number,
    token_type: string
}

export interface Token {
    original: TokenResponse;
}

export interface GetUser {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
}

export interface Stages {
    password: boolean;
    google2fa: boolean;
}

export interface Enable2FA {
    otp: string;
    token: string;
}

export interface AddPasswordRequest {
    password: string;
    password_confirmation: string;
    token: string;
}

interface CurrentUserResponse {
    user: User;
}

interface ForgotPasswordEmailRequest {
    email: string;
}

interface ForgotPasswordResetRequest {
    email: string;
    token: string;
    password: string;
    password_confirmation: string;
}

interface RedirectUrl {
    url: string;
}

class AuthRepository extends Repository {

    public constructor() {
        super('/auth');
    }

    public getGuides(guideName: string) {
        return this.request<UserGuides>('/guide/' + guideName, RequestMethod.GET).send();
    }

    public verifyCredentials(credentials: Credentials) {
        return this.request<RequestError | void>(this.url('/verifyUser'), RequestMethod.POST, {...credentials}).send();
    }

    public resetCredentials(user_id: number) {
        return this.request(this.url('/reset'), RequestMethod.POST, {user_id}).send();
    }

    public get2FAQR(token: string) {
        return this.request<Get2FAQRResponse>(this.url(`/2fa?token=${token}`), RequestMethod.GET).sendRaw();
    }

    public enable2FA(data: Enable2FA) {
        return this.request(this.url('/2fa/enable'), RequestMethod.POST, data).send();
    }

    public getUser(token: string) {
        return this.request<GetUserResponse>(this.url('/getUser?token=' + token), RequestMethod.GET).sendRaw();
    }

    public loginUser(credentials: Credentials) {
        return this.request<LoginResponse>(this.url('/login'), RequestMethod.POST, { ...credentials }).sendRaw();
    }

    public registerUser(user: User) {
        return this.request<LoginResponse>(this.url('/register'), RequestMethod.POST, user).sendRaw();
    }

    public logoutUser() {
        return this.request(this.url('/logout'), RequestMethod.POST).send();
    }

    public refreshToken() {
        return this.request<TokenResponse>(this.url('/refresh'), RequestMethod.POST).sendRaw();
    }

    public getCurrentUser() {
        return this.request<CurrentUserResponse>(this.url('/me'), RequestMethod.POST).sendRaw();
    }

    public addPassword(password: AddPasswordRequest) {
        return this.request(this.url('/addPassword'), RequestMethod.POST, password).send();
    }

    public forgotPassword(mail: ForgotPasswordEmailRequest) {
        return this.request(this.url('/forgot-password/email'), RequestMethod.POST, mail).send();
    }

    public forgotPasswordReset(reset: ForgotPasswordResetRequest) {
        return this.request(this.url('/forgot-password/reset'), RequestMethod.POST, reset).send();
    }

    public changeUser(user: User) {
        return this.request<User>(this.url('/change'), RequestMethod.PUT, user).send();
    }

    public requestDelete() {
        return this.request<User>(this.url('/requestDelete'), RequestMethod.POST).send();
    }

    public getSsoRedirectUrl(id: number) {
        return this.request<RedirectUrl>(this.url(`/sso/redirect/${id}`), RequestMethod.GET).sendRaw();
    }
}

export default AuthRepository;
