import Credentials from '../networking/models/Credentials';

export interface DevCredentials extends Credentials {
    redirect: string;
}

export const getCredentials = (): DevCredentials[] => {
    if(!process.env.REACT_APP_USERS)
        return [];

    return JSON.parse(process.env.REACT_APP_USERS);
};
