import {ModelRepository, RequestMethod} from 'buro-lib-ts';

import LoginAttempt from '../models/LoginAttempt';
import EventLog from '../models/EventLog';

class ObserverRepository extends ModelRepository<any> {
    public constructor() {
      super('/observer');
    }

    public getLogins(page: number) {
      return this.request<LoginAttempt[]>(this.url(`/logins?page=${page}`), RequestMethod.GET).sendPagination();
    }

    public getEvents(page: number) {
      return this.request<EventLog[]>(this.url(`/events?page=${page}`), RequestMethod.GET).sendPagination();
    }

}

export default ObserverRepository;
