import React, { FunctionComponent } from 'react';

import LoginAttempt from './LoginAttempt';
import LoginAttemptModal from '../../../../networking/models/LoginAttempt';
import {useTheme} from '../../../contexts/ThemeContext';

interface Props {
  logins: LoginAttemptModal[];
}

const LoginsList: FunctionComponent<Props> = ({ logins }) => {
    const { theme } = useTheme();

    return (
        <div className={`observer-list observer-list--${theme.modifier}`}>
            <div className={'observer-list__header observer-list__grid--logins'}>
                <span className='observer-list__title'>Log ID</span>
                <span className='observer-list__title'>User ID</span>
                <span className='observer-list__title'>E-Mail</span>
                <span className='observer-list__title'>Meldkamer</span>
                <span className='observer-list__title'>IP-Adres</span>
                <span className='observer-list__title'>Datum</span>
                <span className='observer-list__title'>Tijd</span>
                <span className='observer-list__title'>Succesvol</span>
            </div>

            <div className='observer-list__login-attempts'>
                {logins.map((login: any, index: number) => (
                    <LoginAttempt login={login} key={index}/>
                ))}
            </div>
        </div>
    );
};

export default LoginsList;
