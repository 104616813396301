import React, { ChangeEvent, FunctionComponent } from 'react';

import WayPoint from 'networking/models/WayPoint';

import { getFormattedTime } from 'helpers/Time';

import Button from 'components/layout/buttons/Button';
import CancelButton from '../../../../layout/buttons/CancelButton';

interface Props {
    wavePoint: WayPoint;
    onInputChange: (index: number, value: string) => void;
    onSubmit: (index: number) => void;
    onCancel: (index: number) => void;
    value: string;
    index: number;
    renderCancelButton?: (onClick: () => void, className: string) => any;
}

const WavePointInput: FunctionComponent<Props> = ({ wavePoint,  onSubmit, onCancel, onInputChange, value, index, renderCancelButton }) => {

    const onButtonClick = () => {
        onSubmit(index);
    };

    const onCancelClick = () => {
        onCancel(index);
    };

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        onInputChange(index, e.target.value);
    };

    const cancelButtonClassname = 'audio-player__cancel-button';

    return (
        <div className={'audio-player__wave-point-input'} onKeyUp={(e) => e.stopPropagation()} tabIndex={0}>
            <span>{ wavePoint.index }.</span>
            <span>{ getFormattedTime(wavePoint.seconds) }</span>

            <div className={'input'}>
                <input
                    className={'input__field'}
                    value={value}
                    placeholder={'Plaats een opmerking...'}
                    onChange={onChange} />
            </div>

            { renderCancelButton
                ? renderCancelButton(onCancelClick, cancelButtonClassname)
                : <CancelButton onClick={onCancelClick} className={cancelButtonClassname}>Annuleren</CancelButton>
            }

            <Button onClick={onButtonClick}>Plaats</Button>
        </div>
    );
};

export default WavePointInput;
