import React, { FunctionComponent } from 'react';
import {useTheme} from '../contexts/ThemeContext';

interface Props {
    children: any;
    className?: string;
}

const Card: FunctionComponent<Props> = ({ children, className }) => {
    const { theme } = useTheme();

    return (
        <div className={`white-card white-card--${theme.modifier} ${className ? className : ''}`}>
            { children }
        </div>
    );
};

export default Card;
