import {useMutation} from '@tanstack/react-query';

import AuditCallRepository from '../../../networking/repos/AuditCallRepository';
import AuditCall from '../../../networking/models/AuditCall';
import Question from '../../../networking/models/Question';

interface AnswerQuestionDTO {
    question: Question;
    answer: number;
}

export const useAuditCallAnswerQuestionMutation = (auditId: number, auditCallId: number) => {
    const repo = new AuditCallRepository(auditId);

    return useMutation({
        mutationFn: (dto: AnswerQuestionDTO) => repo.answerQuestion(auditCallId, dto.question, dto.answer),
    });
};

export const useAuditCallUpdateEntranceDataMutation = (auditId: number) => {
    const repo = new AuditCallRepository(auditId);

    return useMutation({
        mutationFn: (auditCall: AuditCall) => repo.updateEntranceData(auditCall),
    });
};

export const useAuditCallFinishMutation = (auditId: number, auditCallId: number) => {
    const repo = new AuditCallRepository(auditId);

    return useMutation({
        mutationFn: () => repo.finishAuditCall(auditCallId),
    });
};

export const useAuditCallUnlockMutation = (auditId: number, auditCallId: number) => {
    const repo = new AuditCallRepository(auditId);

    return useMutation({
        mutationFn: () => repo.unlockAuditCall(auditCallId),
    });
};

export const useAuditCallUpdateCommentMutation = (auditId: number) => {
    const repo = new AuditCallRepository(auditId);

    return useMutation({
        mutationFn: (auditCall: AuditCall) => repo.updateComment(auditCall),
    });
};
