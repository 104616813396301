import {useQueryClient} from '@tanstack/react-query';

import FormPartRepository from '../../../networking/repos/FormPartRepository';

export const useFormPartInvalidator = (formId: number) => {
    const queryClient = useQueryClient();

    return () => queryClient.invalidateQueries(['form-parts', formId]);
};

export const useFormPartIndexQueryConfig = (form: number) => {
    const repo = new FormPartRepository(form);

    return [{
        queryKey: ['form-parts', form],
        queryFn: () => repo.all()
    }];
};
