import {FormState} from '../../components/form/Form';

export interface FormRequest {
    name: string;
    max_error_count: number;
    max_knockout_count: number;
    draft: boolean;
}

// TODO: Right approach? right place?
export const createFormRequest = (form: FormState): FormRequest => {
    return {
        name: form.name,
        max_error_count: parseInt(form.max_error_count),
        max_knockout_count: parseInt(form.max_knockout_count),
        draft: form.draft
    };
};
