import React, {FunctionComponent} from 'react';
import {Draggable} from 'react-beautiful-dnd';
import PartQuestion, { Props as PartQuestionProps } from './PartQuestion';

interface Props extends PartQuestionProps {
    index: number;
}

const DraggableQuestion: FunctionComponent<Props> = (props) => {
    const { question } = props;

    return (
        <Draggable draggableId={question.id!.toString()} index={props.index}>
            {(provided, snapshot) => (
                <div
                    { ...provided.draggableProps }
                    { ...provided.dragHandleProps }
                    ref={provided.innerRef}
                    className={`form-question__container ${snapshot.isDragging ? 'form-question__container--dragging' : ''}`}>
                    <PartQuestion { ...props } />
                </div>
            )}
        </Draggable>
    );
};

export default DraggableQuestion;
