import React, { FunctionComponent } from 'react';
import {useTheme} from '../../contexts/ThemeContext';

interface Props {
    children: any;
    onClick: () => void;
}

const AddButton: FunctionComponent<Props> = ({ children, onClick }) => {
    const { theme } = useTheme();

    return (
        <div className={`add-button add-button--${theme.modifier}`} onClick={onClick}>
            <span className={'add-button__icon'}>
                +
            </span>

            <span className={'add-button__text'}>
                { children }
            </span>
        </div>
    );
};

export default AddButton;

