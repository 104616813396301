import React, {createContext, FunctionComponent, useContext, useState} from 'react';
import {Theme, themes} from '../../themes/theme';

interface Context {
    theme: Theme;
    setTheme: (name: string) => void;
}

const ThemeContext = createContext<Context>({} as Context);
export const useTheme = () => useContext(ThemeContext);

interface Props {
    children: any;
}

const ThemeContextProvider: FunctionComponent<Props> = ({ children }) => {

    const [themeIndex, setThemeIndex] = useState<number>(0);

    const setTheme = (name: string) => {
        const index = themes.findIndex(theme => theme.name === name);

        if(index === -1) {
            console.warn(`Theme ${name} does not exist!`);
            return;
        }

        setThemeIndex(index);
    };

    return (
        <ThemeContext.Provider value={{ theme: themes[themeIndex], setTheme }}>
            { children }
        </ThemeContext.Provider>
    );
};

export default ThemeContextProvider;
