import AuditRepository from '../../../networking/repos/AuditRepository';
import {useIndexQueryConfig, useQueryConfig} from '../Utils';
import {AuditIndexRequest} from '../../../networking/requests/Audit';

// TODO: Add filters
export const useAuditTypeAuditorQueryConfig = (request: AuditIndexRequest) => {
    const repo = new AuditRepository();

    return useIndexQueryConfig(['auditor-audits', request], (page) => repo.getAuditorAudits(request, page));
};

export const useAuditTypeOperatorQueryConfig = (request?: AuditIndexRequest) => {
    const repo = new AuditRepository();

    return useIndexQueryConfig(['operator-audits', request], (page) => repo.getOperatorAudits(request, page));
};

export const useAuditTypeOpenQueryConfig = (request: AuditIndexRequest) => {
    const repo = new AuditRepository();

    return useIndexQueryConfig(['open-audits', request], (page) => repo.getOpenAudits(request, page));
};

export const useAuditTypePassedQueryConfig = (request: AuditIndexRequest) => {
    const repo = new AuditRepository();

    return useIndexQueryConfig(['passed-audits', request], (page) => repo.getPassedAudits(request, page));
};

export const useAuditTypeFailedQueryConfig = (request: AuditIndexRequest) => {
    const repo = new AuditRepository();

    return useIndexQueryConfig(['failed-audits', request], (page) => repo.getFailedAudits(request, page));
};

export const useAuditDetailQueryConfig = (id: number) => {
    const repo = new AuditRepository();

    return useQueryConfig(['audit-detail', id], () => repo.find(id));
};

export const useAuditScoreQueryConfig = (id: number) => {
    const repo = new AuditRepository();

    return useQueryConfig(['audit-score', id], () => repo.getAuditScore(id));
}