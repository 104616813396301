import React, { FunctionComponent } from 'react';

import { Tab as DefaultTab, TabProps } from 'buro-lib-ts';
import {useTheme} from '../../../../contexts/ThemeContext';

interface Props extends TabProps {
    done: boolean;
    disabled?: boolean;
}

const Tab: FunctionComponent<Props> = ({ disabled,children, active, onClick, index, done }) => {
    const { theme } = useTheme();

    const getTabNumber = (): number => {
        return index !== undefined
            ? index + 1
            : 0;
    };

    const isDisabled = () => disabled === true;
 

    return (
        <DefaultTab
            className={`form-tab form-tab--${theme.modifier} ${done ? 'form-tab--done' : ''} ${disabled ? 'form-tab--disabled' : ''}`}
            activeClassName={'form-tab--active'}
            active={active}
            onClick={isDisabled() ? undefined : onClick}
            index={index}>

            <div className={'form-tab__number'}>{ getTabNumber() }</div>

            <span className={'form-tab__name'}>
                { children }
            </span>
        </DefaultTab>
    );
};

export default Tab;
