import React, { FunctionComponent } from 'react';

import Conversation from './Conversation';

import ConversationModel from 'networking/models/AuditCall';
import {useTheme} from '../../../contexts/ThemeContext';

interface Props {
    conversations: ConversationModel[];
    onConversationClick: (conversation: ConversationModel) => void;
}

const ConversationList: FunctionComponent<Props> = ({ conversations, onConversationClick }) => {
    const { theme } = useTheme();

    return (
        <div className={`item-list item-list--${theme.modifier}`}>
            <div className="item-list__grid item-list__header">
                <span className={'item-list__title'}>#</span>
                <span className={'item-list__title'}>Status</span>
                <span className={'item-list__title'}>Datum</span>
                <span className={'item-list__title'}>Duur</span>
                {/*<span className={'conversation-list__title'}>Knockout</span>*/}
                <span className={'item-list__title'}>Actie</span>
            </div>

            <div className="item-list__conversations">
                { conversations.map((conversation, index) => (
                    <Conversation
                        number={index + 1}
                        auditCall={conversation}
                        onClick={onConversationClick}
                        key={conversation.id} />
                ))}
            </div>
        </div>
    );
};

export default ConversationList;

