import React, { FunctionComponent } from 'react';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg';
import {useTheme} from '../../contexts/ThemeContext';

interface Props {
    children?: any;
    onClick: () => void;
}

const BackButton: FunctionComponent<Props> = ({ children, onClick }) => {
    const { theme } = useTheme();

    return (
        <button className={'button button-back button-back--' + theme.modifier} onClick={onClick}>
            <ArrowIcon className={'icon-grey icon-grey--filled button-back__icon'} />
            <span className={'button-back__text'}>{ children ? children : 'Terug' }</span>
        </button>
    );
};

export default BackButton;
